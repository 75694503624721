@import '../../../public/styles/variables';

.sort-bar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  background-color: $white;

  &__label,
  &__item {
    color: $dark;
    font-size: 16px;
    white-space: nowrap;
  }

  &__label {
    margin-right: 8px;

    @media #{$breakpoint-sm-up} {
      margin-right: 0;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    font-weight: bold;
    cursor: pointer;

    @media #{$breakpoint-sm-down} {
      &:after {
        content: '/';
        margin: 0 6px;
        color: $gray;
        font-weight: normal;
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }

    @media #{$breakpoint-sm-up} {
      margin-left: 26px;
    }
  }

  &__text {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 2px;
      background-color: $yellow;
      opacity: 0;
      transition: 0.3s ease;
      transform: translateY(5px);
    }

    &.active {
      &:before {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media #{$breakpoint-sm-up} {
    justify-content: flex-end;
  }
}
