.review-card {
  width: 100%;
  padding: 32px 0;

  &--protected {
    .review-card__content {
      @media #{$breakpoint-md-up} {
        width: 100%;
      }
    }
  }

  &--pending {
    position: relative;

    * {
      color: $gray !important;
    }

    .review-card__product-text {
      color: $red !important;
    }

    .images-view__photo {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .review-card__actions {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__wrapper {
    @media #{$breakpoint-md-up} {
      display: flex;
      align-items: flex-start;
    }
  }

  &__content {
    @media #{$breakpoint-md-up} {
      width: calc(100% - 85px);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 16px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
  }

  &__avatar {
    flex: none;
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }

  &__username {
    font-size: 18px !important;

    &:hover {
      text-decoration: none;
    }

    @media #{$breakpoint-md-up} {
      font-size: 22px !important;
      line-height: 25px !important;
    }
  }

  &__rating {
    height: 16px;

    .dv-star-rating-star .icon {
      font-size: 16px !important;
    }

    @media #{$breakpoint-sm-up} {
      margin-top: 5px;
    }
  }

  &__likes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 16px;

    @media #{$breakpoint-md-up} {
      justify-content: flex-end;
    }
  }

  &__like {
    font-size: 36px !important;
  }

  &__amount {
    margin-left: 8px;
    color: $dark;
    font-size: 13px;
    text-align: right;

    @media #{$breakpoint-md-up} {
      min-width: 25px;
      padding-left: 5px;
      margin-left: 0;
    }
  }

  &__body {
    margin-bottom: 8px;

    @media #{$breakpoint-sm-up} {
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 8px;

    @media #{$breakpoint-sm-up} {
      margin-bottom: 0;
    }
  }

  &__actions {
    @media #{$breakpoint-md-down} {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    @media #{$breakpoint-md-up} {
      flex: none;
      width: 70px;
      margin-left: 15px;
    }
  }

  &__action {
    display: block;
    color: $dark-gray !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: right;

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      @media #{$breakpoint-md-down} {
        margin-left: 0;
      }

      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }
    }

    @media #{$breakpoint-md-down} {
      margin-left: 8px;
    }

    @media #{$breakpoint-md-up} {
      width: 100%;
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__delete {
    color: $red;
  }

  &__details {
    display: flex;
    align-items: center;
    color: $dark-gray;
    font-size: 13px;

    &-link {
      margin-left: 8px;
      color: inherit;
      font-size: 20px;

      &:hover {
        color: $dark;
        text-decoration: none;
      }
    }
  }

  &__product {
    max-width: 210px;
    width: 100%;
    margin-top: 24px;

    &-thumbnail {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    &-link {
      display: flex;
      align-items: flex-start;
      width: 100%;
      color: $dark-gray !important;
      font-size: 13px !important;
      line-height: 17px !important;

      &:hover {
        color: $dark;
        text-decoration: none;
      }
    }

    &-text {
      display: -webkit-box;
      max-height: 34px; // Line height X 2 - for unsupported browsers
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-icon {
      display: inline-block;
      color: $dark;
    }

    &-rating {
      margin: 6px 0;
      display: flex;
      align-items: flex-end;
      font-size: 13px;
      line-height: 13px;
      color: $dark-gray;

      .rating {
        height: 14px;
        margin-right: 6px;
      }
    }

    &-details {
      min-width: 100%;
      flex-shrink: 0;
    }

    @media #{$breakpoint-lg-up} {
      max-width: 400px;
    }
  }

  &__description,
  &__pictures {
    margin-top: 24px;

    @media #{$breakpoint-md-up} {
      margin-right: -85px;
      max-width: unset;
    }
  }

  &__comments {
    padding: 12px 15px;
    margin-top: 16px;
    background-color: $light-gray;

    &-link {
      line-height: 21px;
    }

    .loader-wrapper {
      margin: 10px 0;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 24px;
    }
  }

  &__comment {
    margin-top: 10px;
    padding-bottom: 10px;

    @media #{$breakpoint-md-up} {
      margin-top: 20px;
      padding-bottom: 18px;
    }
  }
}
