.leave-review {
  max-height: 90vh !important;
  max-width: 650px !important;

  &__autocomplete {
    .autocomplete__group-heading {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: normal;
      color: $dark-blue;
    }

    .autocomplete__menu-list {
      max-height: 160px;
    }
  }

  &__form {
    &-title {
      margin-bottom: 40px;
    }

    &-option-add {
      display: flex;
      align-items: center;

      .icon-plus-slim {
        margin-right: 8px;
      }
    }

    &-footer {
      margin-top: 60px;
    }

    &-add-product-btn {
      @media #{$breakpoint-xxs-only} {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &--large {
      .leave-review__form-footer {
        margin-top: 160px;
      }
    }
  }

  &__separator {
    @extend .auth__separator;
  }

  &__files {
    &-title {
      font-size: 16px;
      color: $black;
      margin-bottom: 6px;
    }

    .icon-plus-slim {
      margin-left: 8px;
    }
  }

  &__success {
    text-align: center;

    &-title {
      margin-bottom: 8px;
    }

    &-text {
      margin-bottom: 24px;
    }

    img {
      display: block;
      margin: 16px auto 32px;
      max-width: 100%;
      background-color: $white;

      @media screen and (min-width: $xxs-max) {
        min-height: 245px;
      }
    }
  }

  &__signup {
    &-modal {
      max-width: 800px;
    }

    &-title {
      margin-bottom: 8px;
    }

    &-auth {
      margin: 16px 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;

      .social-auth__btn {
        width: unset;
      }

      @media #{$breakpoint-sm-up} {
        margin: 32px 0 16px;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &-footer {
      margin-top: 30px;
      text-align: center;
    }

    &-img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__back-btn {
    margin-right: 16px;
  }

  &__success-btn {
    margin-top: 24px;
  }

  &__terms {

    .link {
      font-size: inherit !important;
    }
  }
}
