@font-face {
	font-family: 'Telegraf';
	src: url('/fonts/Telegraf-Regular.eot');
	src: url('/fonts/Telegraf-Regular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Telegraf-Regular.woff2') format('woff2'),
		url('/fonts/Telegraf-Regular.woff') format('woff'),
		url('/fonts/Telegraf-Regular.ttf') format('truetype'),
		url('/fonts/Telegraf-Regular.svg#Telegraf-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Telegraf';
	src: url('/fonts/Telegraf-UltraBold.eot');
	src: url('/fonts/Telegraf-UltraBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/Telegraf-UltraBold.woff2') format('woff2'),
		url('/fonts/Telegraf-UltraBold.woff') format('woff'),
		url('/fonts/Telegraf-UltraBold.ttf') format('truetype'),
		url('/fonts/Telegraf-UltraBold.svg#Telegraf-UltraBold') format('svg');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
