@import '../../../public/styles/variables';

.avatar {
  position: relative;
  width: 72px;
  height: 72px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid $gray;
  background-color: $white;

  &--active {
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
      border-radius: 50%;
      border: 3px solid $yellow;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }
}
