@import '../../../../public/styles/variables';
@import '../../../../public/styles/icons';

.header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  overflow: hidden;
  z-index: 1020;
  transition: 0.5s;
  background-color: $white;

  &--open {
    bottom: 0;
    height: 100%;

    .header-mobile__wrapper {
      visibility: visible;
    }
  }

  &__toggle {
    font-size: 26px;

    .icon {
      display: block;
    }
  }

  &__wrapper {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    overflow: auto;
    visibility: hidden;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 10px 15px;
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 8px;
  }

  &__menu {
    li {
      border-bottom: 1px solid $light-gray;

      &:first-child {
        border-top: 1px solid $light-gray;
      }
    }
  }

  &__flat {
    &--about {
      margin-top: 50px;
    }

    .header-mobile__link {
      &:before {
        content: none;
      }
    }
  }

  &__link,
  &__log-out {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px 50px 10px 15px;
    font-size: 20px !important;
    line-height: 27px !important;

    &:before {
      @extend .icon;
      @extend .icon-angle-right;
    }

    &:before {
      position: absolute;
      right: 15px;
      bottom: 18px;
      font-size: 12px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__log-out {
    color: $red;
    border: none;
    outline: none;
    background-color: transparent;

    &:before {
      color: $dark;
    }
  }

  &__favorites,
  &__notifications {
    font-size: 16px;
  }

  &__notifications {
    min-width: 24px;
    height: 24px;
    padding: 1px 5px 0;
    line-height: 24px;
    text-align: center;
    border-radius: 100px;
    background-color: $light-blue;
  }

  .header-mobile__actions {
    margin: 0;
  }

  &__actions {
    min-height: 90px;
    padding: 10px 0;
    text-align: center;
  }

  &__btn {
    max-width: 265px;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }

  &__conditions {
    .bubble-select {
      display: block;
      width: 100%;

      .bubble-select__control {
        padding-top: 20px;
        padding-bottom: 8px;
        border-radius: 0;
      }

      .bubble-select__placeholder,
      .bubble-select__single-value {
        color: $dark;
        font-size: 22px;
        line-height: 27px;
        font-weight: normal;
      }

      .bubble-select__menu {
        position: static;
        left: 0;
        width: 100%;
        margin: 0;
        border: none;
        box-shadow: none;
        text-align: left;
        transform: translate(0);
      }

      .bubble-select__option {
        border-bottom: none;
      }
    }
  }

  @media #{$breakpoint-md-up} {
    display: none;
  }
}
