.form-radio {
  &__wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    line-height: 22px;
    cursor: pointer;
    user-select: none;
  }

  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    border: 2px solid gray;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 3px;
      top: 3px;
      width: 10px;
      height: 10px;
      background: $black;
      border-radius: 50%;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .form-radio__mark {
      border-color: $black;

      &::after {
        display: block;
      }
    }
  }
}
