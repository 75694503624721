.btn {
  display: inline-block;
  padding: 14px 22px;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  font-family: $main-font;
  transition: 0.2s linear box-shadow;
  transition: 0.3s ease-in-out opacity;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:focus {
    outline: thin dotted;
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }

  &--dark {
    color: $white !important;
    background-color: $dark !important;
  }

  &--blue {
    color: $dark;
    background-color: $light-blue;
  }

  &--hide {
    opacity: 0;
    visibility: hidden;
  }

  &--yellow {
    color: $dark;
    background-color: $yellow;

    &:disabled {
      color: rgba($dark, 0.3);
    }
  }

  &--icon {
    font-weight: normal;
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    font-size: 20px;
    display: inline-flex;
    flex-shrink: 0;

    .icon {
      margin: auto;
    }
  }

  &--loading {
    position: relative;
    color: transparent;

    &:disabled {
      color: transparent;
    }
  }

  &--fw {
    width: 100%;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;

    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border-width: 4px;
      border-style: solid;
      border-color: $dark;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: button-loader 1.2s linear infinite;
    }
  }
}

@keyframes button-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
