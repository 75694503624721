.goal {
  margin-bottom: 50px;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(odd) {
    .goal__description {
      &:before {
        @extend .icon-wave;
        right: -5px;
        bottom: -25px;
        color: $gray;
        font-size: 80px;
        transform: rotate(-5deg);

        @media #{$breakpoint-sm-up} {
          right: 0;
          bottom: -40px;
        }

        @media #{$breakpoint-xl-up} {
          right: 0;
          bottom: 5px;
        }
      }
    }
  }

  &:nth-child(even) {
    .goal__description {
      &:before {
        @extend .icon-plus;
        color: $gray;
        font-size: 30px;

        left: -80px;
        bottom: 80px;
      }
    }

    .goal__content {
      @media #{$breakpoint-sm-up} {
        flex-direction: row-reverse;
      }
    }

    .goal__media {
      margin-right: 0;
      margin-left: 30px;

      @media #{$breakpoint-md-up} {
        margin-right: 0;
        margin-left: 80px;
      }

      @media #{$breakpoint-lg-up} {
        margin-right: 0;
        margin-left: 130px;
      }
    }

    .goal__content {
      @media #{$breakpoint-sm-up} {
        text-align: right;
      }
    }

    .goal__title {
      text-align: left;
    }

    .goal__img {
      margin-right: 0;
    }

    .goal__text {
      text-align: left;

      @media #{$breakpoint-lg-up} {
        margin-left: auto;
      }
    }
  }

  &__content {
    @media #{$breakpoint-sm-up} {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__media {
    flex: none;
    width: 50%;
    max-width: 400px;
    margin-right: 30px;
    margin-bottom: 30px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
      margin-right: 80px;
    }
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 240px;

    @media #{$breakpoint-sm-up} {
      margin: 0 auto;
    }
  }

  &__description {
    position: relative;
    width: 100%;
    max-width: 496px;

    &:before,
    &:after {
      @extend .icon;

      position: absolute;
    }

    @media #{$breakpoint-lg-up} {
      padding-bottom: 45px;
    }
  }

  &__title {
    margin-bottom: 5px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 10px;
    }
  }

  &__text {
    position: relative;
  }

  @media #{$breakpoint-sm-up} {
    margin-bottom: 0;
    padding: 25px 0;
    border-bottom: 1px solid $gray;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 40px 20px;
  }

  @media #{$breakpoint-xl-up} {
    padding: 60px 70px;
  }
}
