@import '../../../../public/styles/variables';

.condition-item {
  position: relative;
  display: block;
  min-height: 190px;
  padding: 30px;
  margin-bottom: -1px;
  border: 1px solid $gray;
  transition: ease 0.3s;

  &:not(.condition-item--empty) {
    &:hover {
      background-color: $yellow;

      .condition-item__text,
      .condition-item__arrow {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &--empty {
    display: none;

    @media #{$breakpoint-md-up} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    @media #{$breakpoint-lg-up} {
      max-width: 220px;
    }
  }

  &__media {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 150px;
    height: 150px;

    @media #{$breakpoint-xl-up} {
      width: 200px;
      height: 200px;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__description {
    display: flex;
    align-items: center;
  }

  &__text {
    @media #{$breakpoint-lg-up} {
      opacity: 0;
      transition: ease 0.3s;
      transform: translateY(5px);
    }
  }

  &__arrow {
    margin-left: 8px;
    font-size: 8px;
    color: $dark-gray;

    @media #{$breakpoint-md-up} {
      position: absolute;
      left: 40px;
      bottom: 35px;
      margin-left: 0;
      color: $dark;
      font-size: 22px;
    }

    @media #{$breakpoint-lg-up} {
      opacity: 0;
      transition: ease 0.3s;
      transform: translateX(-10px);
    }

    @media #{$breakpoint-xl-up} {
      left: 45px;
    }
  }

  &__empty {
    font-size: 18px;
    line-height: 24px;

    @media #{$breakpoint-xl-up} {
      font-size: 22px;
      line-height: 27px;
    }
  }

  @media #{$breakpoint-md-up} {
    min-height: 250px;
    padding: 40px;

    &:nth-child(even) {
      border-left: 0;
    }
  }

  @media #{$breakpoint-lg-up} {
    border: 1px solid $gray !important;

    &:nth-child(2),
    &:nth-child(5) {
      border-right: 0 !important;
      border-left: 0 !important;
    }
  }
}
