.profile-info {
  padding: 48px 24px;
  border: 1px solid $gray;
  border-radius: 4px;
  position: relative;

  &__chips {
    margin-bottom: 12px;
  }

  &__edit-btn {
    position: absolute;
    top: 14px;
    right: 24px;
  }
}
