@import '../../../public/styles/variables';

.cures-list {
  &__header {
    margin-bottom: 60px;
  }

  &__title {
    margin-bottom: 12px;

    @media #{$breakpoint-sm-down} {
      font-weight: normal;
    }
  }

  &__tabs {
    padding-top: 0 !important;

    .react-tabs__tab-list {
      max-width: 540px;
      padding-bottom: 30px !important;
    }
  }

  &__text {
    margin-bottom: 40px;

    @media #{$breakpoint-xl-up} {
      margin-bottom: 60px;
    }
  }

  &__search {
    max-width: 655px;
    margin-bottom: 30px;
  }

  &__subtitle {
    margin-bottom: 16px;

    @media #{$breakpoint-sm-up} {
      margin-bottom: 25px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 45px;
    }
  }

  &__tags {
    margin-bottom: 30px;
  }

  &__list {
    margin-bottom: 6px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 16px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__body,
  &__recommend {
    margin-bottom: 20px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 60px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 80px;
    }
  }
}
