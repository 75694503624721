@import '../../../public/styles/variables';

.logo {
  max-width: 115px;

  &__link {
    display: block;
  }

  &__img {
    display: block;
    max-width: 100%;
  }

  @media #{$breakpoint-md-up} {
    max-width: 170px;
  }
}
