.chips {
  word-break: break-all;

  &__title {
    margin-bottom: 8px;
    color: $black;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 1px;
    opacity: 0.5;
    text-transform: uppercase;
  }

  &__list {
    overflow: hidden;
  }

  &__item {
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__empty {
    margin-top: -4px;
    margin-bottom: 2px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    word-break: normal;
  }
}
