.tabs {
  &.tabs--line {
    .react-tabs__tab {
      padding-bottom: 10px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
      }

      &:before {
        z-index: 2;
        opacity: 0;
        transform: scale(0);
        transition: ease 0.3s;
        background-color: $yellow;
      }

      &:after {
        right: -25px;
        background-color: $light-gray;
      }

      &:last-child {
        &:after {
          right: 0;
        }
      }

      &.active {
        &:before {
          bottom: 0;
          height: 2px;
          opacity: 1;
          transform: scale(1);
          background-image: none;
        }
      }
    }
  }

  &.tabs--settings {
    padding: 24px 0 40px;

    .react-tabs__tab-list {
      display: flex;
      flex-wrap: nowrap;
    }

    .react-tabs__tab {
      flex-grow: 1;
      text-align: center;
      padding: 14px 0;
      border-color: $gray;
      border-style: solid;
      border-width: 1px;
      margin: 0;

      &.active {
        background-color: $light-blue;
        border-color: $light-blue;

        &::before {
          display: none;
        }
      }
    }
  }

  .react-tabs__tab-list {
    display: flex;
    padding-bottom: 12px;

    @media #{$breakpoint-md-up} {
      padding-bottom: 22px;
    }
  }

  .react-tabs__tab {
    position: relative;
    margin-right: 24px;
    color: $dark;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        display: block;
        height: 5px;
        background-repeat: no-repeat;
        background-position: 0% 50%;
        background-image: url('/images/dark-line.svg');
      }
    }
  }
}
