@import '../../../../public/styles/variables';

.avatars {
  margin-bottom: 15px;
  min-height: 192px;

  &__text {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    @media #{$breakpoint-sm-down} {
      line-height: 16px;
      text-align: center;
    }
  }

  &__content {
    max-width: 385px;
    width: 100%;
    margin: 0 auto;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    margin: 0 12px 15px;

    .avatar {
      transition: 0.2s linear all;

      &:hover {
        border: 1px solid $yellow;
      }
    }
  }

  &__radio {
    display: none;
  }

  &__label {
    cursor: pointer;
    margin-bottom: 8px;
  }
}
