.rating-with-labels {
  margin-top: -20px;
  margin-bottom: 24px !important;

  .label {
    color: $dark-gray;
    font-size: 13px;
    font-family: $main-font;
    display: none;

    @media #{$breakpoint-xs-up} {
      display: block;
    }
  }

  .form-field {
    margin: 0 8px;
  }
}
