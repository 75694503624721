@import '../../../public/styles/variables';

.text {
  color: $dark;
  font-size: 13px;
  line-height: 19px;

  &--sm {
    @media #{$breakpoint-md-up} {
      font-size: 13px !important;
      line-height: 19px !important;
    }
  }

  &--lg {
    font-size: 16px;
    line-height: 24px;

    @media #{$breakpoint-md-up} {
      font-size: 18px !important;
      line-height: 27px !important;
    }
  }

  &--xl {
    font-size: 16px;
    line-height: 24px;

    @media #{$breakpoint-md-up} {
      font-size: 22px !important;
      line-height: 27px !important;
    }
  }

  &--gray {
    color: $dark-gray;
  }

  @media #{$breakpoint-md-up} {
    font-size: 16px;
    line-height: 24px;
  }
}
