.page-404 {
  &__container {
    min-height: 50vh;
    display: flex;
  }

  &__content {
    margin: auto;
    text-align: center;
  }

  &__header {
    position: relative;
  }
}
