@import '../../../public/styles/variables';

.header {
  height: 60px;
  z-index: 10;

  .condition--contact-us {
    display: flex;
    align-items: center;

    > .icon {
      margin-right: 6px;
      margin-top: -2px;
    }
  }

  @media #{$breakpoint-md-up} {
    height: auto;
  }
}
