#__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main {
        width: 100%;
        flex-grow: 1;
    }
}
