.comments-list {
  padding: 15px;
  background-color: $light-gray;

  &__title {
    margin-bottom: 24px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
  }

  &__form {
    margin-bottom: 24px;
  }

  &__item {
    padding: 15px 0;
    border-top: 1px solid $gray;

    @media #{$breakpoint-sm-up} {
      padding: 22px 0;
    }
  }

  &__empty {
    text-align: center;
  }

  &__btn {
    margin-top: 8px;
  }
}
