.handpicked-cure {
  position: relative;

  &__badge {
    position: absolute;
    left: -7px;
    top: 10px;
    z-index: 1;
  }
}
