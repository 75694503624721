.stroke {
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &--type-1 {
    &:before {
      top: -15%;
      left: -5%;
      right: -5%;
      bottom: -15%;
      background-image: url('/images/strokes/stroke-1.svg');
    }
  }

  &--type-2 {
    &:before {
      top: -30%;
      left: -5%;
      right: -5%;
      bottom: -30%;
      background-image: url('/images/strokes/stroke-2.svg');
    }
  }

  &--type-3 {
    &:before {
      top: -5%;
      left: -4%;
      right: -4%;
      bottom: -30%;
      background-image: url('/images/strokes/stroke-3.svg');
    }
  }

  &--type-4 {
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('/images/strokes/stroke-4.svg');
    }
  }

  &__content {
    position: relative;
  }
}
