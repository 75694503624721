@import '../../../public/styles/variables';

.loader-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  &--loading {
    margin-bottom: 60px;

    .loader-wrapper__content {
      display: none;
    }

    .loader-wrapper__loader {
      display: block;
    }
  }

  &__content {
    flex: none;
    width: 100%;
  }

  &__loader {
    display: none;
    width: 30px;
    height: 30px;

    &:after {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border-width: 4px;
      border-style: solid;
      border-color: $dark;
      border-left-color: transparent;
      border-right-color: transparent;
      animation: wrapper-loader 1.2s linear infinite;

      @media #{$breakpoint-md-up} {
        width: 40px;
        height: 40px;
        border-width: 5px;
      }
    }

    @media #{$breakpoint-md-up} {
      width: 50px;
      height: 50px;
    }
  }
}

@keyframes wrapper-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
