@import '../../../../public/styles/variables';

.select {
  &.select--error {
    .select__label {
      color: $red;
    }

    .select__control {
      border-color: $red;

      &:hover {
        border-color: $red;
      }
    }
  }

  &--visible-indicators {
    .select__value-container--has-value {
      & ~ .select__indicators {
        .select__dropdown-indicator {
          display: flex !important;
        }
      }
    }
  }

  .select__control {
    min-height: 55px;
    padding: 5px 0;
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    border: 2px solid $gray;

    &:hover {
      border-color: $gray;
    }

    &--is-focused {
      border-color: $yellow;

      &:hover {
        border-color: $yellow;
      }
    }
  }

  .select__value-container {
    padding-left: 15px;

    &--has-value {
      & ~ .select__indicators {
        .select__dropdown-indicator {
          display: none;
        }
      }
    }
  }

  .select__placeholder {
    padding-top: 2px;
  }

  .select__single-value {
    padding-top: 2px;
  }

  .select__indicator {
    padding-right: 13px;
  }

  .select__indicator-separator {
    display: none;
  }

  .select__clear-indicator,
  .select__dropdown-indicator {
    color: $dark;
    cursor: pointer;
  }

  .select__clear-indicator {
    font-size: 24px;
  }

  .select__loading-indicator {
    & ~ .select__dropdown-indicator {
      display: none;
    }
  }

  .select__dropdown-indicator {
    font-size: 20px;
  }

  .select__multi-value {
    padding: 6px 8px 4px 10px;
    background-color: $light-gray;

    &__label {
      padding: 1px 8px 0 0;
      font-size: 16px;
    }

    &__remove {
      padding: 0;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: $black;
        background-color: transparent;
      }
    }
  }

  .select__menu {
    margin-top: -2px;
    border: 1px solid $gray;
    box-shadow: -41px 61px 84px rgba(67, 86, 100, 0.15);
    z-index: 11;

    &-list {
      max-height: 160px;
    }
  }

  .select__menu-list {
    padding: 0;
  }

  .select__option {
    padding: 12px 17px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }

    &:active {
      background-color: $light-gray;
    }

    &--is-focused {
      background-color: $light-gray;
    }

    &--is-selected {
      font-weight: bold;
      background-color: transparent;
    }

    &--is-new {
      background-color: $light-blue;
      font-weight: bold;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 12px;
        margin-top: -4px;
      }
    }
  }

  .select__menu-notice {
    padding: 12px;
    color: $dark;
    letter-spacing: 0.5px;
  }
}
