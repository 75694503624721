.images-view {
  max-width: 100%;
  overflow-x: auto;

  &__grid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(5, 90px);

    @media #{$breakpoint-sm-up} {
      grid-gap: 30px;
    }

    @media #{$breakpoint-md-up} {
      grid-template-columns: repeat(5, 160px);
    }
  }

  &__photo {
    width: 90px;
    height: 90px;
    cursor: pointer;
    border: 1px solid $gray;

    @media #{$breakpoint-md-up} {
      width: 160px;
      height: 160px;
    }
  }
}
