.blog-list-title-form {
  display: flex;
  margin: 24px 0 48px;
  position: relative;

  &__form {
    flex-grow: 1;
  }

  &__image {
    display: none;

    @media #{$breakpoint-xl-up} {
      display: block;
      position: absolute;
      right: 7%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media #{$breakpoint-sm-up} {
    margin: 36px 0 60px;
  }
}

.blog-inlist-form {
  display: flex;
  position: relative;
  overflow: hidden;
  margin: 60px 0;

  &__title {
    max-width: 608px;
    margin-bottom: 12px;
  }

  &__subtitle {
    margin-bottom: 24px;
    position: relative;
    max-width: 80%;

    &:before {
      @extend .icon;
      @extend .icon-spin-arrow-right;
    }

    &:before {
      position: absolute;
      right: -70px;
      top: -20px;
      transform: rotate(135deg) scale(1, -1);
      color: $dark;
      font-size: 66px;

      @media #{$breakpoint-sm-up} {
        top: -30px;
        transform: rotate(43deg) scale(1, -1);
      }

      @media #{$breakpoint-xl-up} {
        font-size: 70px;
        right: -30px;
        transform: rotate(33deg) scale(1, -1);
        top: -40px;
      }
    }
  }

  &__image {
    display: none;

    @media #{$breakpoint-xl-up} {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @media #{$breakpoint-sm-up} {
    margin: 96px 0;
  }
}

.blog-subscription-success {
  max-height: 90vh;
  max-width: 650px;

  &__body {
    text-align: center;

    &-title {
      margin-bottom: 8px;
    }

    &-text {
      margin-bottom: 24px;
    }

    img {
      display: block;
      margin: 16px auto 32px;
      max-width: 100%;
      background-color: $white;

      @media screen and (min-width: $xxs-max) {
        min-height: 245px;
      }
    }
  }

  &__success-btn {
    margin-top: 24px;
  }
}

.blog-subscription-categories {
  max-height: 90vh;
  max-width: 650px;

  &__body {
    text-align: center;

    &-title {
      margin-bottom: 8px;
    }

    img {
      display: block;
      margin: 30px auto 42px;
      width: 200px;
    }
  }

  &__tags {
    margin: 24px auto 20px;
    max-width: 295px;

    .tags__item {
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }

  &__success-btn {
    margin-top: 24px;
  }
}
