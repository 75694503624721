@font-face {
  font-family: "icons";
  src: url("/fonts/icons.eot?v=1598013437");
  src: url("/fonts/icons.eot?v=1598013437") format("eot"),
    url("/fonts/icons.woff2?v=1598013437") format("woff2"),
    url("/fonts/icons.woff?v=1598013437") format("woff"),
    url("/fonts/icons.ttf?v=1598013437") format("truetype"),
    url("/fonts/icons.svg#icons?v=1598013437") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {

  &:before {
    content: "\EA01"
  }
}

.icon-angle-right {

  &:before {
    content: "\EA02"
  }
}

.icon-arrow-down {

  &:before {
    content: "\EA03"
  }
}

.icon-arrow-left {

  &:before {
    content: "\EA04"
  }
}

.icon-arrow-right {

  &:before {
    content: "\EA05"
  }
}

.icon-arrow-up {

  &:before {
    content: "\EA06"
  }
}

.icon-bottom-blot {

  &:before {
    content: "\EA07"
  }
}

.icon-chevron-down {

  &:before {
    content: "\EA08"
  }
}

.icon-chevron-left {

  &:before {
    content: "\EA09"
  }
}

.icon-chevron-right {

  &:before {
    content: "\EA0A"
  }
}

.icon-chevron-up {

  &:before {
    content: "\EA0B"
  }
}

.icon-close {

  &:before {
    content: "\EA0C"
  }
}

.icon-comment-blob {

  &:before {
    content: "\EA0D"
  }
}

.icon-comment {

  &:before {
    content: "\EA0E"
  }
}

.icon-edit {

  &:before {
    content: "\EA0F"
  }
}

.icon-envelope {

  &:before {
    content: "\EA10"
  }
}

.icon-eye-close {

  &:before {
    content: "\EA11"
  }
}

.icon-eye-open {

  &:before {
    content: "\EA12"
  }
}

.icon-heart-blob {

  &:before {
    content: "\EA13"
  }
}

.icon-heart {

  &:before {
    content: "\EA14"
  }
}

.icon-info {

  &:before {
    content: "\EA15"
  }
}

.icon-like-blob {

  &:before {
    content: "\EA16"
  }
}

.icon-like {

  &:before {
    content: "\EA17"
  }
}

.icon-link {

  &:before {
    content: "\EA18"
  }
}

.icon-lock-blob {

  &:before {
    content: "\EA19"
  }
}

.icon-lock {

  &:before {
    content: "\EA1A"
  }
}

.icon-menu {

  &:before {
    content: "\EA1B"
  }
}

.icon-plus-slim {

  &:before {
    content: "\EA1C"
  }
}

.icon-plus {

  &:before {
    content: "\EA1D"
  }
}

.icon-search {

  &:before {
    content: "\EA1E"
  }
}

.icon-spin-arrow-left {

  &:before {
    content: "\EA1F"
  }
}

.icon-spin-arrow-right {

  &:before {
    content: "\EA20"
  }
}

.icon-star {

  &:before {
    content: "\EA21"
  }
}

.icon-top-blot {

  &:before {
    content: "\EA22"
  }
}

.icon-wave-2 {

  &:before {
    content: "\EA23"
  }
}

.icon-wave {

  &:before {
    content: "\EA24"
  }
}

