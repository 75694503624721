@import '../../../public/styles/variables';

.info-text {
  display: flex;
  align-items: center;
  color: $dark;
  opacity: 0.5;

  &__icon {
    margin-top: -4px;
    margin-right: 5px;
    font-size: 24px;
  }
}
