@import '../../../public/styles/variables';

.conditions {
  padding: 50px 0 100px;

  &__header {
    margin-bottom: 20px;
    text-align: center;

    @media #{$breakpoint-sm-up} {
      max-width: 625px;
      margin: 0 auto 20px;
    }

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 60px;
    }
  }

  &__title {
    margin-bottom: 20px;
  }

  &__text {
    @media #{$breakpoint-sm-up} {
      padding: 0 25px;
    }
  }

  &__list {
    max-width: 500px;
    margin: 0 auto;

    @media #{$breakpoint-md-up} {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
    }
  }

  &__item {
    @media #{$breakpoint-md-up} {
      width: 50%;
    }

    @media #{$breakpoint-lg-up} {
      width: 33.33%;
    }
  }
}
