.thumbnail {
  background-repeat: no-repeat;
  background-position: center center;

  &--cover {
    background-size: cover;
  }

  &--contain {
    background-size: contain;
  }
}
