.blog-article-card {
  border-radius: 10px;
  border: 1px solid $gray;
  padding: 24px 16px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  > .row {
    flex-grow: 1;
  }

  &__link-wrapper {
    &:hover {
      text-decoration: none;
    }
  }

  &__body {
    max-width: 605px;
  }

  &__categories {
    margin: 0 -8px 14px;
  }

  &__category {
    display: inline-block;
    background-color: $yellow;
    line-height: 16px;
    border-radius: 100px;
    padding: 9px 16px 6px;
    margin: 8px;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__content {
    font-size: 13px;
    line-height: 21px;
    margin-bottom: 8px;
    height: 63px;
    overflow: hidden;
  }

  &__stat {
    color: #7f7f7f;
    font-size: 13px;
    line-height: 21px;

    span {
      &::after {
        content: '';
        margin: 0 12px;
        display: inline-block;
        width: 4px;
        height: 4px;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: #7f7f7f;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  &__footer {
    @media #{$breakpoint-lg-up} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__views {
    position: relative;
    padding-left: 24px;

    .icon-eye-open {
      font-size: 16px !important;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-3px);
    }
  }

  &__more {
    font-size: 16px;
    margin: 16px 0;
  }

  &__thumbnail {
    width: 100%;
    padding-top: 60%;
  }

  @media #{$breakpoint-md-up} {
    padding: 24px 16px 24px 36px;
  }
}
