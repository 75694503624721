.favorites-page {
  padding: 20px 0;

  &__tabs {
    margin: 50px 0 24px;

    &-counter {
      font-weight: normal;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 40px 0;
  }
}
