@import '../../../public/styles/variables';

.collapse {
  &__btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: $dark;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  &__footer {
    margin-top: 24px;
  }

  &__btn {
    .link__icon {
      margin-top: -2px;
      margin-left: 10px;
      font-size: 24px;
    }
  }
}
