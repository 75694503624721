.edit-cure {
  &-page {
    margin: 50px 0;
  }

  &-form {
    text-align: center;
    max-width: 540px;
    margin: 0 auto;

    &-title {
      margin-bottom: 70px;
      font-weight: bold;
    }

    &__footer {
      text-align: right;
    }

    &__cancel-btn {
      margin-right: 16px;
    }
  }
}
