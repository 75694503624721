.review-card-protect {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid $gray;
  cursor: pointer;

  &__blob {
    position: relative;
    margin-bottom: 8px;
    font-size: 24px;

    &:before {
      @extend .icon;
      @extend .icon-lock-blob;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      color: $light-blue;
    }
  }

  &__icon {
    position: relative;
    z-index: 9;
    display: block;
  }

  &__text {
    max-width: 250px;
    margin: 0 auto;
    text-align: center;

    @media #{$breakpoint-md-up} {
      max-width: 100%;
      margin: 0;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 30px;
    margin-left: 80px;
    border-radius: 0;
  }
}
