@import "./grid/grid-mixins";
@import "./grid/visibility";

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.row {
  @include row();
  margin: 0 -5px;

  @media #{$breakpoint-sm-up} {
    margin: 0 -15px;
  }
}

.row.full-height {
  height: 100%;
}

.row.no-wrap {
  flex-wrap: nowrap;
}

.row.no-margin {
  margin: 0 !important;
}

.row.reverse {
  @include row-reverse();
}

.row.natural-height {
  @include row-natural-height();
}

.row.flex-column {
  @include row-flex-column();
}

.col {
  @include col();
}

.col.reverse {
  @include col-reverse();
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *

@mixin col-factory($thisPrefix) {
  @for $i from 1 through $grid-columns {

    .col-#{$thisPrefix}-#{$i} {
      box-sizing: border-box;
      flex-basis: 100% / $grid-columns * $i;
      max-width: 100% / $grid-columns * $i;
      padding: 0 $gutter;

      @media #{$breakpoint-sm-down} {
        padding: 0 5px;
      }
    }

    .col-#{$thisPrefix}-offset-#{$i} {
      margin-left: 100% / $grid-columns * $i;
    }
  }

  .col-#{$thisPrefix} {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: $gutter;
  }

  .jc-start-#{$thisPrefix} {
    justify-content: flex-start;
  }

  .jc-center-#{$thisPrefix} {
    justify-content: center;
  }

  .jc-end-#{$thisPrefix} {
    justify-content: flex-end;
  }

  .ai-top-#{$thisPrefix} {
    align-items: flex-start;
  }

  .ai-middle-#{$thisPrefix} {
    align-items: center;
  }

  .ai-bottom-#{$thisPrefix} {
    align-items: flex-end;
  }

  .jc-around-#{$thisPrefix} {
    justify-content: space-around;
  }

  .jc-between-#{$thisPrefix} {
    justify-content: space-between;
  }

  .first-#{$thisPrefix} {
    order: -1;
  }

  .last-#{$thisPrefix} {
    order: 1;
  }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through length($breakpoint-up-prefixes) {
  $thisPrefix: nth($breakpoint-up-prefixes, $i);

  @if $thisPrefix == "xxs" {
    @include col-factory($thisPrefix);
  }

  @if $thisPrefix == "xs" {
    @media #{$breakpoint-xs-up} {
      @include col-factory($thisPrefix);
    }
  }

  @else if $thisPrefix == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-factory($thisPrefix);
    }
  }

  @else if $thisPrefix == "md" {
    @media #{$breakpoint-md-up} {
      @include col-factory($thisPrefix);
    }
  }

  @else if $thisPrefix == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-factory($thisPrefix);
    }
  }

  @else if $thisPrefix == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-factory($thisPrefix);
    }
  }
}

.col-gutter-lr {
  padding: 0 $gutter;
}

.col-gutter-tb {
  padding: $gutter 0;
}

.col-no-gutter {
  padding: 0;
}
