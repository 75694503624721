@import '../../../public/styles/variables';

.pagination {
  display: flex;
  justify-content: center;

  &--disabled {
    pointer-events: none;
  }

  &__list {
    flex: none;
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;
    margin: 0 2px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      border-radius: 50%;
      background-color: $light-blue;
      transform: scale(0);
      transition: transform 0.3s ease;
    }

    &.active {
      &:after {
        transform: scale(1);
      }
    }
  }

  &__link {
    position: relative;
    z-index: 5;
    display: block;
    min-width: 30px;
    height: 30px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
  }

  &__prev,
  &__next {
    a {
      font-size: 26px;
      cursor: pointer;
    }

    &.disabled {
      color: $gray;
      pointer-events: none;
    }
  }

  &__prev {
    @media #{$breakpoint-md-up} {
      margin-right: 10px;
    }
  }

  &__next {
    @media #{$breakpoint-md-up} {
      margin-left: 10px;
    }
  }

  &__break {
    margin: 0 2px;
    letter-spacing: 1px;
  }

  .pagination__icon {
    display: block;
  }
}
