
@import '../../../public/styles/variables';

.footer {
  padding: 30px 0 25px;
  background-color: $light-blue;

  &__logo {
    margin-bottom: 25px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }

  &__item {
    @media #{$breakpoint-md-up} {
      padding-top: 15px;
    }
  }

  &__title {
    margin-bottom: 10px;
    color: $dark-gray;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  &__list {
    margin-bottom: 30px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }

  &__text {
    color: $dark;
    font-size: 16px;
    line-height: 20px;

    @media #{$breakpoint-md-up} {
      text-align: right;
    }
  }

  &__copyright {
    margin-top: 5px;
    color: $dark-gray;
    font-size: 13px;
    line-height: 16px;

    @media #{$breakpoint-md-up} {
      margin-top: 10px;
      text-align: right;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 45px 0 35px;
  }
}
