.anchors {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  .anchors__btn {
    padding-top: 9px;
    padding-bottom: 9px;
    font-weight: normal;
    transition: font ease .2s, box-shadow ease .3s;

    &:hover {
      font-weight: bold;
    }

    &:before {
      content: attr(title);
      display: block;
      height: 0;
      font-weight: bold;
      overflow: hidden;
      visibility: hidden;
    }
  }
}
