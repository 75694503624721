@import '../../../public/styles/variables';

.modal-alert {
  &__content {
    min-height: 100px;
    padding: 25px 0;

    @media #{$breakpoint-md-up} {
      min-height: 165px;
      padding: 40px 0;
    }
  }

  &__content,
  &__footer {
    text-align: center;
  }
}
