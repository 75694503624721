.setup-profile {
  &__conditions {
    .select__menu-list {
      max-height: 180px;
    }
  }

  &__controls {
    text-align: center;
    margin-top: 50px;
  }
}
