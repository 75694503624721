.dv-star-rating {
  height: 24px;
  overflow: hidden;

  .dv-star-rating-star {
    padding-left: 4px;

    &:last-child {
      padding-left: 0;
    }

    .icon {
      font-size: 24px;
    }
  }
}
