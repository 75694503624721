.settings-page {
  padding: 54px 0;

  &__form {
    margin: 20px 0 60px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__social {
    text-align: center;

    &-title {
      margin-bottom: 35px;
    }

    &-name {
      text-transform: capitalize;
    }

    &-img {
      display: block;
      max-width: 100%;
      margin: 0 auto 24px;
    }

    &-resend {
      margin-top: 16px;
    }
  }
}
