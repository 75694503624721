.blog-page {
  padding-bottom: 60px;

  &__title {
    &-stroke {
      padding: 23px 5px;
    }

    &-blob {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        left: -20% !important;
        right: -15% !important;
        top: -8% !important;
        bottom: -50%;
        z-index: -1;
        background-image: url('/images/blobs/blob-blue-1.svg');
      }
    }

    @media #{$breakpoint-md-up} {
      font-weight: bold !important;
    }
  }

  &__subtitle {
    max-width: 634px;
    margin-top: 16px;
    line-height: 1.5;
  }
}
