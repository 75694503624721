.error-page {
  min-height: 50vh;
  padding: 50px 0 90px;

  &__content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  &__media {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__title {
    margin-top: 20px;

    @media #{$breakpoint-lg-up} {
      margin-top: 40px;
    }
  }

  &__text {
    margin-top: 10px;
  }

  &__btn {
    margin-top: 30px;
  }
}
