@import '../../../public/styles/variables';

.cures-search {
  &__header {
    margin-bottom: 20px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 50px;
    }
  }

  &__tabs {
    margin-bottom: 16px;

    &-counter {
      font-weight: normal;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 24px;
    }
  }
}
