@import '../../../public/styles/variables';

.chip {
  display: inline-block;
  padding: 4px 8px;
  color: $dark;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid $gray;
  background-color: $light-gray;
  word-break: break-all;
}
