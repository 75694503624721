@import '../../../public/styles/variables';

.field {
  &--error {
    .field-input__field,
    .field-textarea__field {
      border-color: $red;

      &:focus {
        border-color: $red;
      }
    }
  }

  &--password {
    .field-input__field {
      padding-right: 50px;
    }
  }
}
