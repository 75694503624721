@import '../../../public/styles/variables';

.breadcrumbs {
  margin-bottom: 40px;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    flex: none;
    display: flex;
    align-items: center;
    color: $dark;
    font-size: 16px;
    line-height: 21px;

    &:after {
      content: '/';
      margin: 0 8px;
      color: $gray;
    }

    &:last-child {
      &:after {
        content: none
      }
    }
  }

  &__link {
    display: block;
    padding: 5px 12px;
    color: inherit;
    border: 1px solid $gray;

    &:hover {
      text-decoration: underline;
    }
  }

  @media #{$breakpoint-md-down} {
    display: none;
  }

  @media #{$breakpoint-lg-up} {
    margin-bottom: 40px;
  }
}
