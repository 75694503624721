@import "public/styles/variables";

.confirmation-dialog {
  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    margin: 36px auto 0;

    &--inverted {
      flex-direction: row-reverse;
      justify-content: flex-start;
    }

    .btn,
    .link {
      margin: 1rem;
      flex-grow: 1;

      @media #{$breakpoint-sm-up} {
        margin: 0 1rem 1rem;
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }

  &__body {
    font-size: 18px;
    line-height: 27px;
  }
}
