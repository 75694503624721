@import '../../../public/styles/variables';

.blob {
  &:before {
    @media #{$breakpoint-md-up} {
      content: '';
      position: absolute;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  &__content {
    @media #{$breakpoint-md-up} {
      position: relative;
    }
  }

  &--yellow {
    &.blob--type-1 {
      &:before {
        @media #{$breakpoint-md-up} {
          top: -5%;
          left: 0;
          right: 25px;
          bottom: -50%;
          background-image: url('/images/blobs/blob-yellow-1.svg');
        }
      }
    }
  }

  &--blue {
    &.blob--type-1 {
      &:before {
        @media #{$breakpoint-md-up} {
          top: -5%;
          left: 0;
          right: 25px;
          bottom: -50%;
          background-image: url('/images/blobs/blob-blue-1.svg');
        }
      }
    }
    &.blob--type-2 {
      &:before {
        @media #{$breakpoint-md-up} {
          top: -60%;
          left: -2%;
          right: -5%;
          bottom: -40%;
          background-image: url('/images/blobs/blob-blue-2.svg');
        }
      }
    }
  }

  &--gray {
    &.blob--type-1 {
      &:before {
        @media #{$breakpoint-md-up} {
          top: -10%;
          left: -10px;
          right: -10px;
          bottom: -35%;
          background-image: url('/images/blobs/blob-gray-1.svg');
        }
      }
    }

    &.blob--type-2 {
      &:before {
        @media #{$breakpoint-md-up} {
          top: -5%;
          left: 0;
          right: 0;
          bottom: -15%;
          background-image: url('/images/blobs/blob-gray-2.svg');
        }
      }
    }
  }

  @media #{$breakpoint-md-up} {
    position: relative;
    display: inline-block;
  }
}
