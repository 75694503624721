@import '../../../public/styles/variables';

.editor-content {
  color: $dark;
  font-size: 13px;
  line-height: 19px;

  &__content {
    & > * {
      &:first-child {
        margin-top: 0 !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    p {
      margin-bottom: 10px;
      color: $dark;
      font-size: 13px;
      line-height: 19px;

      @media #{$breakpoint-md-up} {
        font-size: 16px;
        line-height: 24px;
      }
    }

    a {
      color: $dark;
      text-decoration: underline;

      &:hover {
        color: $dark-blue;
        text-decoration: none;
      }
    }

    ul, ol {
      margin: 15px 0;
      padding-left: 20px;

      li {
        margin-bottom: 10px;
        color: $dark;
        font-size: 13px;
        line-height: 19px;

        &:last-child {
          margin-bottom: 0;
        }

        @media #{$breakpoint-md-up} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    ul {
      li {
        padding-left: 5px;
        list-style: disc;
      }
    }

    ol {
      li {
        padding-left: 5px;
        list-style: decimal;
      }
    }
  }

  @media #{$breakpoint-md-up} {
    font-size: 16px;
    line-height: 24px;
  }
}
