$rem-baseline: 16px;

$grid-columns: 12 !default; // Set number of columns in the grid
$xxs-max: 365px !default; // Set xs breakpoint's max width
$xs-max: 575px !default; // Set xs breakpoint's max width
$sm-max: 768px !default; // Set sm breakpoint's max width
$md-max: 992px !default; // Set md breakpoint's max width
$lg-max: 1200px !default; // Set lg breakpoint's max width
$xl-max: 1450px !default; // Set lg breakpoint's max width

$gutter: 15px !default; // Set gutter size

$content-well-max-width: 'none' !default; // Set the max-width of the content well

$xs-start: ($xxs-max + 1); // Generate xs breakpoint's min width
$sm-start: ($xs-max + 1); // Generate sm breakpoint's min width
$md-start: ($sm-max + 1); // Generate md breakpoint's min width
$lg-start: ($md-max + 1); // Generate lg breakpoint's min width
$xl-start: ($lg-max + 1); // Generate xl breakpoint's min width

// Breakpoints
// Create breakpoint range statements to be used in media queries
$breakpoint-xxs-only: 'only screen and (max-width: #{$xs-max})'; // 0 -> xxs-max range
$breakpoint-xs-up: 'only screen and (min-width: #{$xs-start})'; // 0 -> xs-max range
$breakpoint-xs-down: 'only screen and (max-width: #{$xs-start})'; // 0 -> xs-max range
$breakpoint-xs-only: 'only screen and (max-width: #{$xs-max})'; // 0 -> xs-max range
$breakpoint-sm-up: 'only screen and (min-width: #{$sm-start})'; // sm-start -> up range
$breakpoint-sm-down: 'only screen and (max-width: #{$sm-start})'; // sm-end -> down range
$breakpoint-sm-only: 'only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})'; // sm-start -> sm-max range
$breakpoint-md-up: 'only screen and (min-width: #{$md-start})'; // md-start -> up range
$breakpoint-md-down: 'only screen and (max-width: #{$md-start})'; // md-end -> down range
$breakpoint-md-only: 'only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})'; // md-start -> md-max range
$breakpoint-lg-up: 'only screen and (min-width: #{$lg-start})'; // lg-start -> up range
$breakpoint-lg-down: 'only screen and (max-width: #{$lg-start})'; // lg-end -> down range
$breakpoint-lg-only: 'only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})'; // lg-start -> lg-max range
$breakpoint-xl-up: 'only screen and (min-width: #{$xl-start})'; // xl-start -> up range
$breakpoint-xl-max-up: 'only screen and (min-width: #{$xl-max})'; // xl-start -> up range
$breakpoint-xl-down: 'only screen and (max-width: #{$xl-start})'; // xl-end -> down range

$breakpoints-all: (
  $breakpoint-xxs-only,
  $breakpoint-xs-only,
  $breakpoint-sm-up,
  $breakpoint-sm-only,
  $breakpoint-md-up,
  $breakpoint-md-only,
  $breakpoint-lg-up,
  $breakpoint-lg-only,
  $breakpoint-xl-up
);
$breakpoint-ups: (
  $breakpoint-xxs-only $breakpoint-xs-up,
  $breakpoint-sm-up,
  $breakpoint-md-up,
  $breakpoint-lg-up,
  $breakpoint-xl-up
);
$breakpoint-all-prefixes: (
  'xxs',
  'xs',
  'xs-only',
  'sm',
  'sm-only',
  'md',
  'md-only',
  'lg',
  'lg-only',
  'xl'
);
$breakpoint-up-prefixes: ('xxs', 'xs', 'sm', 'md', 'lg', 'xl');

// fonts
$main-font: 'Telegraf', sans-serif;

// colors
$light-blue: #caf1f0;
$dark-blue: #143849;
$blue: #43a2c8;

$light-gray: #f9f9f9;
$gray: #d1d3d4;
$dark-gray: #7f8b8c;

$yellow: #f7e353;
$white: #ffffff;
$black: #000000;
$dark: #1b1d21;
$pink: #ffb4ca;
$red: #d95f5f;
