@import '../../../public/styles/variables';

.modal-open {
  overflow: hidden;
}

.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: rgba($dark, 0.3);
  }

  &__content {
    position: relative;
    flex: none;
    border-radius: 4px;
    max-width: 600px;
    width: 100%;
    max-height: 80vh;
    overflow: auto;
    padding: 20px 15px;
    background-color: $white;

    @media #{$breakpoint-md-up} {
      padding: 32px;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 0;
    color: $dark;
    font-size: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;

    .icon {
      display: block;
    }

    @media #{$breakpoint-md-up} {
      top: 16px;
      right: 16px;
    }
  }

  &__title {
    margin-bottom: 20px;

    @media #{$breakpoint-md-down} {
      font-size: 26px;
      line-height: 30px;
      text-align: center;
    }
  }

  &__submit {
    display: flex;
    margin-top: 35px;

    &--center {
      justify-content: center;
    }
  }
}
