@import '../../../../public/styles/variables';
@import '../../../../public/styles/icons';

.header-desktop {
  display: none;

  &__content {
    @media #{$breakpoint-md-up} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
    }
  }

  &__logo,
  &__actions,
  &__conditions {
    @media #{$breakpoint-md-up} {
      flex-grow: 1;
    }

    @media #{$breakpoint-lg-up} {
      flex-basis: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__conditions {
    text-align: center;

    .condition--contact-us {
      justify-content: center;
    }
  }

  &__create {
    @media #{$breakpoint-md-up} {
      margin-left: 10px;
    }

    @media #{$breakpoint-xl-up} {
      margin-left: 18px;
    }
  }

  &__review {
    position: relative;

    &:before {
      @extend .icon;
      @extend .icon-spin-arrow-right;
    }

    &:before {
      position: absolute;
      left: -70px;
      bottom: -10px;
      color: $dark;
      font-size: 50px;

      @media #{$breakpoint-xl-up} {
        left: -110px;
        bottom: -15px;
        font-size: 80px;
      }
    }
  }

  &__menu {
    position: relative;
    cursor: pointer;

    &:hover {
      .header-desktop__dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
      }
    }
  }

  .header-desktop__avatar {
    position: relative;
    margin-left: 20px;

    &-img {
      width: 56px;
      height: 56px;
    }

    &-notifications {
      position: absolute;
      right: -4px;
      bottom: -4px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      height: 25px;
      padding: 0 2px;
      font-size: 12px;
      line-height: 13px;
      border-radius: 50%;
      border: 2px solid $white;
      background-color: $light-blue;
    }

    @media #{$breakpoint-xl-up} {
      margin-left: 45px;
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 255px;
    padding-top: 10px;
    opacity: 0;
    visibility: hidden;
    background-color: $white;
    transform: translateY(10px);
    transition: 0.3s ease;

    @media #{$breakpoint-xl-up} {
      padding-top: 20px;
    }
  }

  &__list {
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid $gray;

    & > li {
      border-bottom: 1px solid $light-gray;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &__link,
  &__log-out {
    width: 100%;
    text-align: left;

    &:hover {
      text-decoration: none;
      background-color: $light-gray;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 17px 10px;
  }

  &__log-out {
    padding: 12px 17px;
    color: $red;
    font-size: 16px;
    line-height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  &__notifications {
    min-width: 24px;
    height: 24px;
    padding: 1px 5px 0;
    line-height: 24px;
    text-align: center;
    border-radius: 100px;
    background-color: $light-blue;
  }

  @media #{$breakpoint-md-up} {
    display: block;
    padding: 10px 0;
  }

  @media #{$breakpoint-xl-up} {
    padding: 20px 0;
  }
}
