.flat-page {
  padding-top: 20px;
  padding-bottom: 40px;

  &__header {
    margin-bottom: 25px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }
  }

  @media #{$breakpoint-md-up} {
    padding-bottom: 80px;
  }

  @media #{$breakpoint-lg-up} {
    padding-top: 40px;
    padding-bottom: 120px;
  }
}
