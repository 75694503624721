@import '../../public/styles/variables';

.combination {
  padding-top: 20px;
  padding-bottom: 40px;

  @media #{$breakpoint-md-up} {
    padding-bottom: 80px;
  }

  @media #{$breakpoint-lg-up} {
    padding-top: 40px;
    padding-bottom: 120px;
  }

  @media #{$breakpoint-xl-up} {
    padding-bottom: 150px;
  }
}
