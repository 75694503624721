.combination-form {
  &__field {
    &:last-child {
      margin-bottom: 0;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__preview {
    @media #{$breakpoint-md-down} {
      display: none;
    }
  }

  &__info {
    margin-top: 10px;
  }

  &__example {
    margin-top: 15px;
    margin-bottom: 20px;
    color: $dark-gray;

    @media #{$breakpoint-md-up} {
      margin-bottom: 30px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;
    }
  }

  &__cancel {
    margin-left: 24px;
  }
}
