@import '../../../public/styles/variables';

.tag {
  display: inline-block;
  padding: 5px 12px;
  color: $dark;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  border-radius: 100px;
  border: 1px solid $light-blue;
  cursor: pointer;

  &--active {
    background-color: $light-blue;
  }
}
