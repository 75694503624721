.notifications-list {
  margin: 40px 0;
}

.notifications-item {
  color: inherit;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0;
  border-bottom: 1px solid $gray;

  &--unread {
    background-color: $light-gray;
  }

  &:last-child {
    border-bottom: 0;
  }

  &__thumbnail {
    width: 80px;
    height: 80px;
    background-color: $light-gray;
    border-radius: 4px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  &__title {
    display: flex;
    flex-flow: row wrap;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 7px;

    @media #{$breakpoint-sm-up} {
      flex-flow: row nowrap;
    }
  }

  &__body {
    flex-grow: 1;
    margin-right: 16px;
  }

  &__created {
    margin-left: auto;
    min-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @media #{$breakpoint-sm-up} {
      min-width: unset;
    }
  }

  &__unread-mark {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $light-blue;
    border: 2px solid $white;
    margin-right: 2px;
    margin-bottom: 3px;
  }
}
