.success-story {
  position: relative;
  height: 100%;
  padding-top: 36px;

  &--disabled {
    .success-story__avatar,
    .success-story__title,
    .success-story__toggle,
    .success-story__link,
    .success-story__link-wrapper {
      pointer-events: none !important;
    }
  }

  &--empty {
    .success-story__content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background-color: $light-blue;

      @media #{$breakpoint-md-up} {
        padding-left: 50px;
        padding-right: 50px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-height: 505px;
    height: 100%;
    padding: 50px 15px 20px;
    border-radius: 4px;
    border: 1px solid $gray;

    @media #{$breakpoint-lg-up} {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    }
  }

  &__avatar {
    position: absolute;
    top: 0;
  }

  &__like {
    position: absolute;
    top: 40px;
    right: 16px;
    text-align: center;
  }

  &__toggle {
    font-size: 55px !important;
  }

  &__count {
    font-size: 13px;
    min-width: 25px;
    text-align: right;
  }

  &__title {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: bold;
    line-height: 21px;
  }

  &__text {
    display: -webkit-box;
    height: 42px;
    overflow: hidden;
    margin-bottom: 16px;
    font-size: 14px !important;
    line-height: 21px !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__body {
    flex-grow: 1;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  &__link-wrapper {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__chips {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__empty {
    &-media {
      max-width: 360px;
      margin: 0 auto 30px;

      @media #{$breakpoint-lg-up} {
        margin-bottom: 50px;
      }
    }

    &-img {
      display: block;
      width: 100%;
    }

    &-title {
      margin-bottom: 16px;
    }
  }
}
