@import '../../../../public/styles/variables';

.review-sources {
  margin-bottom: 50px;
  text-align: center;

  &__media {
    max-width: 140px;
    margin: 0 auto 16px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 32px;
    color: $black;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @media #{$breakpoint-sm-up} {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__icon {
    margin-top: -5px;
    margin-right: 8px;
    color: $yellow;
    font-size: 22px;
    vertical-align: middle;
  }

  &__list {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;

  }

  &__item {
    min-width: 100%;
    margin-bottom: 32px;

    &:last-child {
      @media #{$breakpoint-sm-down} {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    padding: 12px 14px;
    font-weight: bold;
    margin-bottom: 10px;

    @media #{$breakpoint-sm-down} {
      width: 100%;
    }

    @media #{$breakpoint-sm-up} {
      min-width: 250px;
    }
  }

  &__wave {
    margin-top: 12px;
    color: $gray;
    font-size: 18px;

    @media #{$breakpoint-sm-up} {
      margin-top: 20px;
    }

    @media #{$breakpoint-xl-up} {
      margin-top: 30px;
    }
  }

  @media #{$breakpoint-lg-up} {
    margin-bottom: 80px;
  }
}
