.add-cure-option {
  text-align: left;
  cursor: pointer;

  &__text,
  &__action {
    color: $dark;
    font-size: 16px;
    line-height: 21px;
  }

  &__text {
    margin-bottom: 6px;
  }

  &__action {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-top: -2px;
    margin-right: 10px;
  }
}
