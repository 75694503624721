@import 'variables';
@import 'reset';
@import 'fonts';
@import 'icons';
@import 'grid';
@import 'layout';
@import '../../components/Auth/Auth';
@import '../../components/Auth/SignIn/SignIn';
@import '../../components/Auth/SignUp/SignUp';
@import '../../components/Auth/SocialAuth/SocialAuth';
@import '../../components/Blog/SubscribeForms/SubscribeForms';
@import '../../components/Blog/BlogPage';
@import '../../components/Blog/BlogArticlesPage';
@import '../../components/Blog/Article/BlogArticle';
@import '../../components/Blog/ArticleCard/BlogArticleCard';
@import '../../components/Blog/List/BlogArticlesList';
@import '../../components/Blog/RelatedArticles/RelatedArticles';
@import '../../components/Blog/SubscribeField/SubscribeField';
@import '../../components/Combinations/Combination';
@import '../../components/Combinations/Details/CombinationDetails';
@import '../../components/Combinations/Form/CombinationForm';
@import '../../components/Combinations/ListPage/CombinationList';
@import '../../components/Conditions/Condition';
@import '../../components/ContactForm/ContactForm';
@import '../../components/Cure/Cure';
@import '../../components/Cure/HandpickedCure';
@import '../../components/Cures/Cures';
@import '../../components/Cures/Details/CureDetails';
@import '../../components/Cures/Details/BuySources/BuySources';
@import '../../components/Cures/Details/Rater/Rater';
@import '../../components/Cures/Details/ReviewSources/ReviewSources';
@import '../../components/Cures/List/CuresList';
@import '../../components/Cures/ListPage/Helpful/Helpful';
@import '../../components/Cures/ListPage/Recommend/Recommend';
@import '../../components/Cures/ListPage/CuresList';
@import '../../components/Cures/ListPage/CuresList';
@import '../../components/Cures/SearchPage/CuresSearch';
@import '../../components/FlatPage/FlatPage';
@import '../../components/Landing/Banner/Banner';
@import '../../components/Landing/Conditions/Conditions';
@import '../../components/Landing/Conditions/ConditionItem/ConditionItem';
@import '../../components/Landing/Goals/Goals';
@import '../../components/Landing/Goals/Goal/Goal';
@import '../../components/Landing/Invitation/Invitation';
@import '../../components/Landing/Matters/Matters';
@import '../../components/Landing/Matters/Matter/Matter';
@import '../../components/LeaveReview/LeaveReview';
@import '../../components/LeaveReview/RatingWithLabels/RatingWithLabels';
@import '../../components/Modals/ModalAlert/ModalAlert';
@import '../../components/Modals/ModalReport/ReportModal';
@import '../../components/Modals/ModalSetupProfile/ModalSetupProfile';
@import '../../components/Modals/SurveyAB/SureyAB';
@import '../../components/Notifications/List/NotificationsList';
@import '../../components/Page404/Page404';
@import '../../components/Profile/EditForm/EditProfileForm';
@import '../../components/Profile/Info/ProfileInfo';
@import '../../components/Profile/Page/ProfilePage';
@import '../../components/Reviews/Reviews';
@import '../../components/Reviews/Card/ReviewCard';
@import '../../components/Reviews/Card/Description/ReviewCardDescription';
@import '../../components/Reviews/Card/Protect/ReviewCardProtect';
@import '../../components/Reviews/Details/ReviewDetails';
@import '../../components/Reviews/EditForm/ReviewEditForm';
@import '../../components/SetupProfileForm/SetupProfileForm';
@import '../../components/Survey/Profile/SurveyProfile';
@import '../../components/Survey/Welcome/SurveyWelcome';
@import '../../components/shared/ActionIcon/ActionIcon';
@import '../../components/shared/AnchorTabs/AnchorTabs';
@import '../../components/shared/Autocomplete/Autocomplete';
@import '../../components/shared/Autocomplete/AddCureOption/AddCureOption';
@import '../../components/shared/Avatar/Avatar';
@import '../../components/shared/Blob/Blob';
@import '../../components/shared/Breadcrumbs/Breadcrumbs';
@import '../../components/shared/BubbleSelect/BubbleSelect';
@import '../../components/shared/Button/Button';
@import '../../components/shared/Chip/Chip';
@import '../../components/shared/Chips/Chips';
@import '../../components/shared/Collapse/Collapse';
@import '../../components/shared/CommentCard/CommentCard';
@import '../../components/shared/CommentsForm/CommentsForm';
@import '../../components/shared/CommentsList/CommentsList';
@import '../../components/shared/ConfirmationDialog/ConfirmationDialog';
@import '../../components/shared/CTA/CTA';
@import '../../components/shared/EditorContent/EditorContent';
@import '../../components/shared/EmptyState/EmptyState';
@import '../../components/shared/Error/Error';
@import '../../components/shared/ErrorPage/ErrorPage';
@import '../../components/shared/Footer/Footer';
@import '../../components/shared/Footer/FooterList/FooterList';
@import '../../components/shared/Form/Field';
@import '../../components/shared/Form/File/File';
@import '../../components/shared/Form/Input/Input';
@import '../../components/shared/Form/Radio/Radio';
@import '../../components/shared/Form/Select/Select';
@import '../../components/shared/Form/SelectAvatar/Avatars';
@import '../../components/shared/Form/Textarea/Textarea';
@import '../../components/shared/FormikField/FormField';
@import '../../components/shared/GuestComments/GuestComments';
@import '../../components/shared/Header/Header';
@import '../../components/shared/Header/MobileHeader/MobileHeader';
@import '../../components/shared/Header/DesktopHeader/DesktopHeader';
@import '../../components/shared/ImageViewer/ImageViewer';
@import '../../components/shared/ImgLightbox/ImgLightbox';
@import '../../components/shared/InfoText/InfoText';
@import '../../components/shared/Link/Link';
@import '../../components/shared/LoaderWrapper/LoaderWrapper';
@import '../../components/shared/Logo/Logo';
@import '../../components/shared/Modal/Modal';
@import '../../components/shared/MoreCures/MoreCures';
@import '../../components/shared/Pagination/Pagination';
@import '../../components/shared/Rating/Rating';
@import '../../components/shared/Rating/Rating';
@import '../../components/shared/ReviewsList/ReviewsList';
@import '../../components/shared/Slider/Slick';
@import '../../components/shared/Slider/Slider';
@import '../../components/shared/SortBar/SortBar';
@import '../../components/shared/Stepper/Stepper';
@import '../../components/shared/Stroke/Stroke';
@import '../../components/shared/SuccessModal/SuccessModal';
@import '../../components/shared/SuccessStory/SuccessStory';
@import '../../components/shared/Tabs/Tabs';
@import '../../components/shared/Tag/Tag';
@import '../../components/shared/Tags/Tags';
@import '../../components/shared/Text/Text';
@import '../../components/shared/Thumbnail/Thumbnail';
@import '../../components/shared/Title/Title';
@import '../../components/shared/Title/Title';
@import '../../components/shared/Underline/Underline';
@import 'pages/AboutPage';
@import 'pages/CombinationCreate';
@import 'pages/ContactUs';
@import 'pages/EditCurePage';
@import 'pages/EditProfile';
@import 'pages/EditReviewPage';
@import 'pages/NotificationsPage';
@import 'pages/FavoritesPage';
@import 'pages/SettingsPage';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: $main-font;
}

body {
  min-height: 100%;
}

li,
ol {
  list-style: none;
}
