@import '../../../public/styles/variables';

.sign-up {
  .auth__title {
    margin-bottom: 20px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 60px;
    }
  }

  .auth__media {
    display: none;

    @media #{$breakpoint-sm-up} {
      display: block;
      margin-bottom: 30px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 60px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 90px;
    }
  }
}
