@import '../../../../public/styles/variables';

.footer-list {
  &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    color: $dark;
    font-size: 16px;
    line-height: 24px;
  }
}
