.blog-related {
  border-top: 1px solid $gray;
  padding: 36px 0 60px;

  &__title {
    margin-bottom: 24px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 48px;
    }
  }

  &__item {
    height: 100%;
    padding-bottom: $gutter;

    &-link {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .blog-article-card {
      height: 100%;
      margin-bottom: 0;
      transition: .2s linear all;

      &__content {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 42px;
      }

      &:hover {
        cursor: pointer;
        background-color: $yellow;

        .blog-article-card__category {
          background-color: $light-gray;
        }
      }
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 60px 0 120px;
  }
}
