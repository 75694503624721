.empty-state {
  padding: 40px 0;
  text-align: center;

  &--sm {
    padding: 0;

    .empty-state__img {
      margin: 0 auto 20px !important;
    }

    @media #{$breakpoint-md-up} {
      padding: 0 !important;
    }
  }

  &__img {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: 0 auto 40px;

    @media #{$breakpoint-md-up} {
      margin: 0 auto 80px;
    }
  }

  &__title {
    margin-bottom: 16px;
  }

  @media #{$breakpoint-md-up} {
    padding: 80px 0;
  }
}
