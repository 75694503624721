@import '../../../../public/styles/variables';

.recommend {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  &__title {
    max-width: 260px;
    margin-bottom: 15px;
    color: $dark;
    font-size: 22px;
    line-height: 27px;
  }

  &__media {
    max-width: 400px;
    width: 100%;
    margin-bottom: 15px;
  }

  &__title,
  &__media {
    @media #{$breakpoint-md-up} {
      margin-bottom: 30px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }
}
