@import '../../../public/styles/variables';

.goals {
  padding: 50px 0;

  &__title {
    margin-bottom: 50px;
    text-align: center;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 100px;
    }
  }
}
