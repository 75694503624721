.comments-form {
  &__title {
    width: 100%;
    margin-bottom: 8px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  &__form {
    @media #{$breakpoint-sm-up} {
      display: flex;
      align-items: center;
    }
  }

  &__fields {
    flex-grow: 1;
  }

  &__btns {
    flex: none;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media #{$breakpoint-sm-down} {
      margin-top: 15px;
    }

    @media #{$breakpoint-sm-up} {
      margin-left: 15px;
    }

    @media #{$breakpoint-md-up} {
      margin-left: 30px;
    }
  }

  &__btn {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$breakpoint-sm-down} {
      width: 100%;
    }
  }

  .comments-form__field {
    margin-bottom: 0;
  }
}
