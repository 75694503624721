@import '../../../public/styles/variables';

.cure-details {
  &__title {
    margin-bottom: 12px;
    word-break: break-all;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 20px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;
    }

    @supports (word-break: break-word) {
      word-break: break-word !important;
    }
  }

  .cure-details__title {
    @media #{$breakpoint-sm-down} {
      font-size: 20px;
      line-height: 25px;
    }
  }

  &__header {
    @media #{$breakpoint-md-up} {
      margin-bottom: 80px;
    }
  }

  &__rating {
    @media #{$breakpoint-xl-up} {
      padding-left: 0 !important;
    }
  }

  &__slider {
    .slick-slide {
      padding: 0 5px;
    }

    .slider__dots {
      padding-top: 5px;

      @media #{$breakpoint-md-up} {
        padding-top: 10px;
      }

      @media #{$breakpoint-lg-up} {
        padding-top: 20px;
      }
    }

    .slick-arrow {
      display: none !important;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
    }

    .slick-prev {
      left: 5px;
    }

    .slick-next {
      right: 5px;
    }

    &:hover {
      .slick-arrow {
        display: block !important;
      }
    }

    @media #{$breakpoint-md-down} {
      margin-bottom: 20px;
    }
  }

  &__actions {
    @media #{$breakpoint-md-down} {
      margin-bottom: 40px;
      order: 1;
    }
  }

  &__action-buttons {
   display: flex;
  }

  &__slide {
    height: 250px;

    @media #{$breakpoint-lg-up} {
      height: 300px;
    }

    @media #{$breakpoint-xl-up} {
      height: 370px;
    }
  }

  &__favorites {
    display: flex;
    align-items: center;

    @media #{$breakpoint-md-down} {
      margin-top: 24px;
    }
  }

  &__favorite {
    margin-right: 8px;
  }

  &__btns {
    margin-top: 24px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      @media #{$breakpoint-lg-up} {
        margin-bottom: 25px;
      }
    }

    @media #{$breakpoint-lg-up} {
      margin-top: 30px;
    }
  }

  &__btn {
    min-width: 185px;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;

    @media #{$breakpoint-md-down} {
      width: 100%;
    }
  }

  &__body {
    margin-bottom: 40px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 60px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 100px;
    }
  }

  &__info {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }
  }

  &__subtitle,
  &__text {
    color: $dark;
  }

  &__subtitle {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 21px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 16px;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 19px;

    @media #{$breakpoint-md-up} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__collapse {
    .collapse__body {
      max-height: 170px;
      height: auto !important;
      overflow: hidden !important;

      @media #{$breakpoint-md-up} {
        max-height: 72px;
      }
    }

    &.collapse--opened {
      .collapse__body {
        max-height: none !important;
      }

      .collapse__footer {
        display: none;
      }
    }
  }

  .editor-content {
    p {
      margin-bottom: 24px;
    }

    ul,
    ol {
      margin: 24px 0;

      li {
        margin-bottom: 0;
      }
    }
  }

  &__controls {
    margin: 24px 0;

    .link:hover {
      text-decoration: none;
    }

    &-edit {
      color: $dark-gray;
    }

    &-delete {
      margin-top: 8px;
      display: block;
      color: $red;
    }
  }

  &__comments {
    margin: 100px 0;
  }

  &__likes {
    display: flex;
    align-items: flex-start;
    margin-top: 8px;

    &--mobile {
      margin-top: 0;

      .cure-details__likes-button {
        display: flex;
        align-items: center;

        .cure-details__likes-counter {
          margin-right: 4px;
        }
      }

      @media #{$breakpoint-md-down} {
        margin-top: 24px;
      }

      @media #{$breakpoint-lg-up} {
        display: none;
      }
    }

    &--desktop {
      margin-left: auto;
      display: none;

      .action-icon {
        font-size: 55px;
      }

      @media #{$breakpoint-lg-up} {
        display: block;
      }
    }

    &-button {
      text-align: center;
      font-size: 12px;
      margin-right: 8px;
    }

    &-counter {
      font-size: 13px;
      margin-top: 4px;
    }
  }
}
