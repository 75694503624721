@import '../../../../public/styles/variables';

.rater {
  padding: 16px 16px 20px;
  border: 1px solid $gray;

  &__title {
    font-size: 13px;
    min-width: 100%;
    max-width: 45%;
    text-align: center;
    margin-bottom: $gutter;

    @media #{$breakpoint-xl-up} {
      text-align: left;
      min-width: unset;
      margin-bottom: 0;
    }
  }

  &__total {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
  }

  &__score {
    text-align: center;

    &--value {
      color: $gray;
      font-size: 40px;
      line-height: 50px;

      > span {
        color: $dark;
      }

      @media #{$breakpoint-lg-up} {
        margin-bottom: 5px;
        font-size: 56px;
        line-height: 59px;
      }
    }

    @media #{$breakpoint-xl-up} {
      text-align: right;
    }
  }

  &__rating {
    height: 24px;
  }

  &__btn {
    margin-top: 8px;
  }

  &__sources {
    margin-top: 20px;
    font-size: 13px;
    line-height: 20px;
    color: #7f7f7f;

    a {
      color: inherit;
      text-decoration: underline;

      &.external {
        color: $blue;
      }

      &.broken {
        text-decoration: none;
      }
    }
  }

  &__breakdowns {
    margin-top: 22px;
  }

  &__breakdown {
    font-size: 13px;
    color: #7f7f7f;
    display: flex;
    line-height: unset !important;
    margin-bottom: 10px;

    &-label {
      width: 85px;
      text-align: right;
    }

    &-progress {
      flex-grow: 1;
      height: 20px;
      background-color: #f2f2f2;
      margin: 0 20px;

      &-inner {
        height: 20px;
      }
    }

    &-percent {
      font-size: 16px;
      line-height: 24px;
      width: 46px;
      overflow: hidden;
      color: #8d8e90;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .dv-star-rating {
      height: unset !important;
    }
    .dv-star-rating-empty-star {
      display: none;
    }
  }
}
