.buy-sources {
  padding: 16px 20px;
  margin-bottom: 50px;
  border-radius: 4px;
  border: 1px solid $gray;

  &__title {
    margin-bottom: 8px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 16px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 24px;
    }
  }

  &__text {
    color: $dark;
    font-size: 16px;
    line-height: 24px;

    @media #{$breakpoint-md-up} {
      max-width: 500px;
    }
  }

  &__footer {
    position: relative;
    display: inline-block;
    min-width: 80px;
    margin-top: 26px;

    &:before {
      @extend .icon;
      @extend .icon-spin-arrow-left;
      position: absolute;
      top: -10px;
      right: -120px;
      color: $dark;
      font-size: 70px;

      @media #{$breakpoint-md-up} {
        top: -15px;
        right: -110px;
      }
    }

    @media #{$breakpoint-xs-up} {
      min-width: 150px;
    }

    @media #{$breakpoint-sm-up} {
      min-width: 100px;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 24px;
    }
  }

  &__btns {
    li {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      @media #{$breakpoint-sm-up} {
        margin-right: 24px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @media #{$breakpoint-sm-up} {
      display: flex;
    }
  }

  &__media {
    display: none;
    max-width: 255px;
    margin-left: 20px;

    @media #{$breakpoint-md-up} {
      display: block;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  @media #{$breakpoint-md-up} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 24px;
  }

  @media #{$breakpoint-lg-up} {
    margin-bottom: 80px;
  }

  @media #{$breakpoint-xl-up} {
    margin-bottom: 100px;
  }
}
