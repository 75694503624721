@import '../../../public/styles/variables';

.bubble-select {
  display: inline-block;

  &--blue {
    .bubble-select__control {
      background-color: $light-blue;
    }
  }

  &--yellow {
    .bubble-select__control {
      background-color: $yellow;
    }
  }

  &--bold {
    .bubble-select__placeholder,
    .bubble-select__single-value {
      font-weight: bold;
    }
  }

  .bubble-select__control {
    padding: 4px 16px 2px 18px;
    border: none;
    box-shadow: none;
    border-radius: 30px;
    cursor: pointer;

    &--menu-is-open {
      .bubble-select__dropdown-indicator {
        transform: rotate(180deg)
      }
    }
  }

  .bubble-select__loading-indicator {
    margin-left: 10px;
    color: $dark;

    & ~ .bubble-select__dropdown-indicator {
      display: none;
    }
  }

  .bubble-select__indicator-separator {
    display: none;
  }

  .bubble-select__dropdown-indicator {
    margin-bottom: 2px;
    color: $dark;
    font-size: 24px;
  }

  .bubble-select__indicator {
    padding: 0;
  }

  .bubble-select__placeholder,
  .bubble-select__single-value {
    position: static;
    top: 0;
    margin: 0;
    color: $dark;
    transform: translate(0);
  }

  .bubble-select__single-value {
    max-width: 100%;
    white-space: normal;
  }

  .bubble-select__value-container {
    padding: 0;
  }

  .bubble-select__menu {
    left: 50%;
    width: auto;
    min-width: 255px;
    text-align: center;
    border: 1px solid $gray;
    box-shadow: -41px 61px 84px rgba(67, 86, 100, 0.15);
    transform: translateX(-50%);
  }

  .bubble-select__menu-list {
    padding: 0;
  }

  .bubble-select__option {
    padding: 12px 17px;
    color:$dark;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
    }

    &:active {
      background-color: $light-gray;
    }

    &--is-focused {
      background-color: $light-gray;
    }

    &--is-selected {
      font-weight: bold;
      background-color: transparent;
    }
  }
}
