.review-card-description {
  &__title {
    flex: none;
    margin-right: 8px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
  }

  .review-card-description__chips {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$breakpoint-sm-up} {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .review-card-description__chip {
    margin: 4px 8px 4px 0px;
  }
}
