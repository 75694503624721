.about-page {
  padding-top: 40px;
  padding-bottom: 40px;

  &__header {
    margin-bottom: 20px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 10px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 20px;
    }
  }

  &__section {
    margin-bottom: 40px;
  }

  &__subtitle {
    margin-bottom: 15px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 25px;
    }
  }

  &__text {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__img {
    display: block;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  &__btns {
    margin-top: 30px;
    text-align: center;

    @media #{$breakpoint-md-up} {
      margin-top: 40px;
    }
  }

  &__cta {
    margin-top: 40px;

    .cta__text {
      max-width: 750px;
    }

    .cta__media {
      max-width: 180px;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 60px;
    }
  }

  @media #{$breakpoint-md-up} {
    padding-bottom: 80px;
  }

  @media #{$breakpoint-lg-up} {
    padding-top: 40px;
    padding-bottom: 120px;
  }
}
