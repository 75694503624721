.edit-profile-form {
  &__field {
    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;
    }
  }

  &__preview {
    margin-top: 15px;

    .info-text {
      margin-top: 16px;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 32px;
    }
  }
}
