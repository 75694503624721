@import '../../../../public/styles/variables';

.matter-item {
  &__rating {
    margin-bottom: 24px;
    pointer-events: none;
  }

  &__reviews {
    margin-bottom: 24px;
  }

  &__subtitle {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  &__chips {
    margin-bottom: 24px;
  }
}
