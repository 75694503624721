.review-edit-form {
  text-align: center;
  max-width: 540px;
  margin: 0 auto;

  &__title {
    margin-bottom: 70px;
  }

  &__footer {
    text-align: right;
  }

  &__cancel-btn {
    margin-right: 16px;
  }
}
