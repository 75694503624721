@import '../ArticleCard/BlogArticleCard';

.blog-article {
  max-width: 850px;
  margin: 0 auto;
  padding: 0 $gutter;

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    margin-bottom: 24px;

    @media #{$breakpoint-md-up} {
      font-size: 36px;
      line-height: 54px;
      font-weight: bold;
    }
  }

  &__header {
    @extend .blog-article-card__stat;

    margin-bottom: 12px;
  }

  &__content {
    font-size: 20px;
    line-height: 38px;

    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.83em;
    }
    h6 {
      font-size: 0.67em;
    }
    p {
      margin: 16px 0;
    }
    img {
      margin: 30px 0;
      height: auto !important;
      max-width: 90% !important;
    }
    blockquote {
      margin: 24px 0;
      padding-left: 10px;
      position: relative;
      font-weight: bold;
      font-size: inherit;
      line-height: 24px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: $yellow;
      }

      @media #{$breakpoint-md-up} {
        padding-left: 20px;
      }
    }
    ol {
      margin: 16px 0 24px;
      padding-left: 16px;

      li {
        list-style: decimal;
      }
    }
    ul {
      margin: 16px 0 24px;
      list-style: none;

      li::before {
        content: '•';
        color: $yellow;
        font-size: 20px;
        font-weight: bold;
        margin-right: 14px;
      }
    }
    li {
      margin: 16px 0;
    }
  }

  &__footer {
    margin: 16px 0 24px;
    color: #7f7f7f;

    @media #{$breakpoint-md-up} {
      margin: 24px 0 32px;
    }
  }

  &__views {
    @extend .blog-article-card__views;
  }
}
