.survey-profile {
  &__header {
    height: 60px;
    display: flex;
    align-items: center;

    img {
      max-width: 115px;

      @media #{$breakpoint-md-up} {
        max-width: unset;
      }
    }

    @media #{$breakpoint-md-up} {
      height: 90px;
    }
  }
  &__form {
    max-width: 560px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 42px;
  }

  &__section {
    padding: 10% 0;
    min-height: 100vh;
    display: flex;
  }

  &__setup {
    width: 100%;
    margin: auto;
  }

  &__question {
    width: 100%;
    margin: auto;
    text-align: center;

    &-title {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 36px;
      text-align: left;

      @media #{$breakpoint-md-up} {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &-subtitle {
      color: $dark-gray;
      font-size: 24px;
      line-height: 36px;
      text-align: left;
    }

    .form-field {
      margin-top: 44px;
      margin-bottom: 40px;
    }

    .form-radio {
      text-align: left;
    }
  }

  &__radio {
    margin: 24px 0 !important;
  }

  &__submit {
    margin-top: 42px;
  }
}
