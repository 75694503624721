@import '../../../public/styles/variables';

.more-cures {
  &__title {
    margin-bottom: 24px;
  }

  &__item {
    margin-bottom: 12px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }
}
