.blog-article-page {
  padding-bottom: 60px;

  &__form {
    .blog-inlist-form {
      margin: 0;
    }
  }

  @media #{$breakpoint-md-up} {
    padding-bottom: 120px;
  }
}
