.survey-ab-modal {
  text-align: center;

  &__title {
    margin-bottom: 24px;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  &__subtitle {
    margin-bottom: 8px;
  }

  &__img {
    width: 176px;
    margin-bottom: 16px;
  }

  &__short {
    margin-bottom: 16px;
  }

  &__main {
    margin-bottom: 32px;
    line-height: 24px;
  }
}
