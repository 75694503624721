.form-file {
  &__dropzone {
    background-color: $light-gray;
    padding: 14px 0;
    border-radius: 4px;
    border: 2px dashed $gray;
    text-align: center;
    color: #7f7f7f;
    font-size: 13px;
    margin-bottom: 10px;
    transition: 0.2s linear background-color;

    &:hover {
      background-color: darken($light-gray, 5%);
    }
  }

  &__thumbs-container {
    max-width: 100%;
    overflow: auto;
  }

  &__thumbs-list {
    display: grid;
    grid-gap: 12px;
    margin: 20px 0 20px;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);

    @media #{$breakpoint-sm-up} {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__thumb {
    display: block;
    background-size: cover;
    position: relative;
    border: 1px solid $light-gray;

    &-btn {
      background-color: rgba(255, 255, 255, 0.75);
      color: $black;
      border-radius: 0;
      position: absolute;
      font-size: 24px;
      right: 0;
      top: 0;
    }

    &--img {
      width: 100%;
      padding-top: 100%;
    }
  }
}
