.underline {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  &--type-1 {
    &:before {
      height: 2px;
      background-image: url('/images/lines/line-black-1.svg');
    }
  }

  &--type-2 {
    &:before {
      height: 5px;
      background-image: url('/images/lines/line-black-2.svg');
    }
  }

  &--type-3 {
    &:before {
      height: 5px;
      background-image: url('/images/lines/line-black-3.svg');
    }
  }
}
