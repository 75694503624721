.matters {
  padding: 50px 0;

  &__heading {
    max-width: 800px;
    margin: 0 auto 60px;
    text-align: center;

    .title:first-child {
      margin-bottom: 20px;
    }

    &-text {
      max-width: 530px;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
  }

  &__animation {
    position: relative;
    min-height: 450px;
  }

  &__animate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: 0.5s ease 0s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: 0.5s ease 0.5s;
    }
  }

  &__item {
    max-width: 500px;
    margin: 0 auto 15px;

    &--left {
      @media #{$breakpoint-md-up} {
        text-align: left;
        padding-right: 35px;
      }

      @media #{$breakpoint-lg-up} {
        padding-right: 0;
      }
    }

    &--right {
      @media #{$breakpoint-md-up} {
        text-align: right;
        padding-left: 35px;
      }

      @media #{$breakpoint-lg-up} {
        padding-left: 0;
      }
    }

    @media #{$breakpoint-md-down} {
      padding-right: 25px;
    }
  }

  &__title {
    margin-bottom: 18px;
  }

  &__media {
    max-width: 195px;
    margin: 0 auto 45px;

    @media #{$breakpoint-lg-up} {
      max-width: 320px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__typist {
    display: inline-block;
  }
}
