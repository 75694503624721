.auth {
  padding: 50px 0;

  &__header {
    @media #{$breakpoint-lg-up} {
      max-width: 920px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__title {
    margin-bottom: 20px;
    text-align: center;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 70px;
    }
  }

  &__link {
    color: $dark;
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
  }

  &__content {
    @media #{$breakpoint-sm-up} {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__separator {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &:before,
    &:after {
      content: '';
      flex-grow: 1;
      display: block;
      height: 1px;
      background-color: $gray;
    }

    span {
      margin: 0 8px;
      color: $dark-gray;
      font-size: 16px;
      line-height: 24px;
    }

    @media #{$breakpoint-sm-up} {
      margin: 32px 0;
    }
  }

  &__form {
    text-align: center;
  }

  &__fields {
    margin-bottom: 25px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }
  }

  &__field {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__forgot {
    @media #{$breakpoint-sm-up} {
      margin-top: -25px;
      text-align: right;
    }
  }

  &__terms {
    margin-bottom: 12px;

    .link {
      font-size: inherit !important;
    }
  }

  &__subtitle {
    margin: 24px 0;
  }

  @media #{$breakpoint-lg-up} {
    padding: 70px 0 150px;
  }

  @media #{$breakpoint-xl-up} {
    padding: 100px 0 200px;
  }
}
