@import '../../../public/styles/variables';
@import '../../../public/styles/icons';

.invitation {
  padding: 40px 0;
  background-color: $yellow;

  &__content {
    text-align: center;
  }

  &__title {
    margin-bottom: 10px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 30px;
    }
  }

  &__text {
    margin-bottom: 45px;

    @media #{$breakpoint-sm-up} {
      max-width: 540px;
      margin: 0 auto 30px;
    }
  }

  &__subtext {
    text-align: center;
    opacity: 0.5;
  }

  &__btn {
    position: relative;
    margin-bottom: 20px;

    &:before,
    &:after {
      @extend .icon;

      position: absolute;
      color: $dark;
      font-size: 32px;
    }

    &:before {
      @extend .icon-top-blot;
      top: -18px;
      left: -40px;
    }

    &:after {
      @extend .icon-bottom-blot;
      right: -40px;
      bottom: -18px;
    }
  }

  @media #{$breakpoint-lg-up} {
    padding: 45px 0 30px;
  }
}
