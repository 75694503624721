@import '../../../public/styles/variables';

.error {
  color: $red;
  font-size: 16px;
  text-align: left;

  &--non-field {
    margin-bottom: 25px;
    text-align: center;
  }
}
