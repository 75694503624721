.reviews-list {
  margin-bottom: 40px;

  &__title {
    margin-bottom: 12px;
    word-break: break-all;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 20px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;
    }

    @supports (word-break: break-word) {
      word-break: break-word !important;
    }
  }

  &__item {
    margin-bottom: 30px;
    border-bottom: 1px solid $gray;

    &:last-child {
      border-bottom: none;
    }

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    button {
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }
  }

  &__more {
    display: flex;
    align-items: center;
    color: $dark;
    font-size: 16px;
    line-height: 21px;
    font-family: $main-font;
  }

  &__icon {
    font-size: 24px;
  }

  .reviews-list__item {
    padding: 0 0 30px;

    &:last-child {
      padding-bottom: 0;
    }

    @media #{$breakpoint-md-up} {
      padding-bottom: 40px;
    }
  }

  @media #{$breakpoint-md-up} {
    margin-bottom: 80px;
  }

  @media #{$breakpoint-lg-up} {
    margin-bottom: 100px;
  }
}
