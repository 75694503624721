.comment-card {
  &__wrapper {
    @media #{$breakpoint-md-up} {
      display: flex;
      align-items: flex-start;
    }
  }

  &__content {
    @media #{$breakpoint-md-up} {
      width: calc(100% - 85px);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 16px;
    }
  }

  &__username {
    margin-left: 16px;
    font-size: 18px;

    &:hover {
      text-decoration: none;
    }

    @media #{$breakpoint-md-up} {
      margin-left: 30px;
      font-size: 22px;
      line-height: 25px;
    }
  }

  &__date {
    margin-top: 4px;
    opacity: 0.5;
  }

  &__likes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 16px;

    @media #{$breakpoint-md-up} {
      justify-content: flex-end;
    }
  }

  &__like {
    font-size: 36px;
  }

  &__amount {
    margin-left: 8px;
    color: $dark;
    font-size: 13px;
    text-align: right;

    @media #{$breakpoint-md-up} {
      min-width: 25px;
      padding-left: 5px;
      margin-left: 0;
    }
  }

  &__actions {
    @media #{$breakpoint-md-down}  {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    @media #{$breakpoint-md-up} {
      flex: none;
      width: 70px;
      margin-left: 15px;
    }
  }

  &__action {
    display: block;
    color: $dark-gray;
    font-size: 16px;
    line-height: 24px;
    text-align: right;

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      @media #{$breakpoint-md-down}  {
        margin-left: 0;
      }

      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }
    }

    @media #{$breakpoint-md-down}  {
      margin-left: 8px;
    }

    @media #{$breakpoint-md-up} {
      width: 100%;
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__delete {
    color: $red;
  }

  &__form {
    margin-top: 24px;

    .comments-form {
      &__form {
        @media #{$breakpoint-sm-up} {
          align-items: flex-start;
        }
      }

      &__btns {
        margin-top: 8px;
      }
    }
  }
}
