@import '../../../public/styles/variables';

.tags {
  &__item {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &__action {
    margin-top: 4px;
  }

  &__show {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    color: $dark;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    outline: none;
    border-radius: 100px;
    border: 1px solid $light-blue;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      background-color: $light-blue;
    }
  }

  &__icon {
    margin-left: 5px;
    font-size: 22px;
  }
}
