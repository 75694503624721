.survey-welcome {
  text-align: center;

  &__img {
    display: block;
    max-width: 350px;
    width: 100%;
    margin: 0 auto 45px;
    transform: scale(-1, 1);
  }

  &__title {
    max-width: 870px;
    margin: 0 auto 24px;
    text-align: center;
  }

  &__subtitle {
    max-width: 730px;
    margin: 0 auto 38px;
    text-align: center;
  }

  &__stat {
    max-width: 730px;
    margin: 0 auto 40px;
    border-top: 1px solid $gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__stat-item {
    font-weight: 400;
    margin-top: 12px;
    font-size: 24px;
    line-height: 36px;
    flex-basis: 100%;

    &-title {
      color: #7f7f7f;
    }

    @media #{$breakpoint-sm-up} {
      flex-basis: unset;
    }
  }

  &__btn {
    margin-bottom: 90px;
    position: relative;

    &:before {
      @extend .icon;
      @extend .icon-spin-arrow-right;

      display: none;
      position: absolute;
      right: -120px;
      top: -25px;
      transform: scale(-1, 1) rotate(7deg);
      font-size: 80px;

      @media #{$breakpoint-sm-up} {
        display: block;
      }
    }
  }
}
