@import '../../../public/styles/variables';
@import '../../../public/styles/icons';

.slider {
  .slick-arrow {
    position: absolute;
    z-index: 100;
    padding: 0;
    color: $dark;
    font-size: 0;
    border: none;
    outline: none;
    cursor: pointer;
    transform: translateY(-50%);
    background-color: transparent;

    &:before {
      @extend .icon;
      font-size: 20px;
    }

    &.slick-disabled {
      cursor: default;

      &:before {
        color: $gray;
      }
    }
  }

  .slick-prev {
    top: 50%;
    left: 0;

    &:before {
      @extend .icon-chevron-left;
    }
  }

  .slick-next {
    top: 50%;
    right: 0;

    &:before {
      @extend .icon-chevron-right;
    }
  }

  &__dots {
    display: flex !important;
    justify-content: center;
    padding-top: 25px;

    li {
      width: 8px;
      height: 8px;
      margin: 0 3px;
      line-height: 0;
      border-radius: 50%;
      background-color: $gray;

      &.slick-active {
        background-color: $yellow;
      }

      @media #{$breakpoint-md-up} {
        margin: 0 6px;
      }
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 0;
      line-height: 0;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
}
