.blog-subscribe-field {
  position: relative;

  &__button {
    width: 100%;
    margin-top: 9px;

    @media #{$breakpoint-sm-up} {
      margin-top: 0;
      width: unset;
      position: absolute;
      right: 3px;
      top: 3px;
      height: 49px;
    }
  }

  @media #{$breakpoint-sm-up} {
    max-width: 635px;
  }
}
