@import '../../../public/styles/variables';
@import '../Form/Select/Select';

.autocomplete {
  position: relative;

  &.autocomplete--icon {
    .autocomplete__value-container {
      padding-left: 50px;
    }

    .autocomplete__placeholder,
    .autocomplete__single-value {
      left: 50px;
    }
  }

  &.autocomplete--btn-text {
    .autocomplete__placeholder,
    .autocomplete__single-value {
      right: 110px;
    }
  }

  &.autocomplete--btn-icon {
    .autocomplete__placeholder,
    .autocomplete__single-value {
      right: 55px;
    }
  }

  &.autocomplete--yellow {
    .autocomplete__control {
      &--is-focused {
        border-color: $yellow;
      }

      &:hover {
        border-color: $yellow;
      }
    }

    .autocomplete__btn {
      background-color: $yellow;
    }
  }

  &.autocomplete--blue {
    .autocomplete__control {
      &--is-focused {
        border-color: $light-blue;
      }

      &:hover {
        border-color: $light-blue;
      }
    }

    .autocomplete__btn {
      background-color: $light-blue;
    }
  }

  &.autocomplete--primary {
    .autocomplete__control {
      border: 2px solid $gray;

      &:hover {
        border-color: $gray;
      }

      &--is-focused {
        border-color: $yellow;

        &:hover {
          border-color: $yellow;
        }
      }
    }

    .autocomplete__option {
      @extend .select__option;
    }
  }

  .autocomplete__icon {
    position: absolute;
    top: 0;
    left: 12px;
    bottom: 0;
    z-index: 9;
    color: $dark;
    font-size: 28px;
    line-height: 55px;
  }

  .autocomplete__control {
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid $gray;
  }

  .autocomplete__value-container {
    height: 55px;
  }

  .autocomplete__placeholder,
  .autocomplete__single-value {
    left: 8px;
  }

  .autocomplete__single-value {
    padding-top: 2px;
  }

  .autocomplete__placeholder {
    right: 10px;
    padding-top: 2px;
    color: $dark;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    opacity: 0.5;
  }

  .autocomplete__menu {
    z-index: 100;
    margin: 0;
    border-radius: 8px;
    border: 1px solid $gray;
  }

  .autocomplete__menu-list {
    padding: 0;
  }

  .autocomplete__group {
    padding: 8px 0;

    &:first-child {
      padding-top: 16px;
    }

    &:last-child {
      padding-bottom: 16px;
    }
  }

  .autocomplete__group-heading {
    margin-bottom: 6px;
    color: $dark;
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    text-transform: none;
  }

  .autocomplete__option {
    padding: 10px 13px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;

    &:active {
      background-color: transparent;
    }

    &--is-selected {
      font-weight: bold;
      background-color: inherit;
    }

    &--is-focused {
      background-color: $light-gray;
    }
  }

  .autocomplete__menu-notice {
    padding: 12px;
    color: $dark;
    letter-spacing: 0.5px;
  }

  &__btn {
    position: absolute;
    padding: 5px;
    color: $dark;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    outline: none;
    border-radius: 4px;
    cursor: pointer;

    &--text {
      top: 3px;
      right: 3px;
      bottom: 3px;
      min-width: 100px;
    }

    &--icon {
      top: 6px;
      right: 6px;
      bottom: 6px;
      min-width: 48px;

      .autocomplete__icon {
        position: static;
        line-height: 34px;
      }
    }
  }
}
