@import '../../../public/styles/variables';

.combination-list {
  &__header {
    margin-bottom: 25px;

    @media #{$breakpoint-sm-up} {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }
  }

  &__title {
    @media #{$breakpoint-sm-down} {
      margin-bottom: 15px;
    }
  }

  &__btn {
    @media #{$breakpoint-xs-only} {
      width: 100%;
      text-align: center;
    }
  }

  &__body {
    max-width: 550px;
    margin: 0 auto 10px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 30px;
    }

    @media #{$breakpoint-lg-up} {
      max-width: 100%;
      margin-bottom: 40px;
    }
  }

  &__item {
    margin-bottom: 5px;
  }
}
