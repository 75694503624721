.combination-create {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__intro {
    @media #{$breakpoint-md-down} {
      margin-bottom: 30px;
    }

    @media #{$breakpoint-md-up} {
      padding-top: 20px;
    }

    @media #{$breakpoint-lg-up} {
      padding-top: 40px;
    }
  }

  &__title {
    margin-bottom: 5px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__media {
    display: none;

    @media #{$breakpoint-md-up} {
      display: block;
      max-width: 150px;
    }

    @media #{$breakpoint-lg-up} {
      max-width: 100%;
    }
  }

  &__img {
    display: block;
    max-width: 100%;
  }

  .combination-create__breadcrumbs {
    margin-bottom: 0;
  }
}
