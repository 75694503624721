.contact-us {
  padding: 100px 0;

  &__container {
    background-size: 445px 445px;
    background-position: 0 100%;
    background-repeat: no-repeat;

    @media #{$breakpoint-md-up} {
      padding-bottom: 100px;
      background-image: url('/images/write-contact.svg');
    }
  }
}
