.stepper {
  display: flex;
  justify-content: center;

  &__step {
    width: 12px;
    height: 12px;
    margin: 0 4px;
    line-height: 0;
    border-radius: 50%;
    background-color: $gray;

    &--active {
      background-color: $yellow;
    }
  }
}
