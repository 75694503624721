.edit-profile {
  padding: 20px 0 60px;


  &__header {
    @media #{$breakpoint-md-down} {
      margin-bottom: 30px;
    }

    @media #{$breakpoint-md-up} {
      margin-bottom: 65px;
    }
  }

  &__title {
    margin-bottom: 5px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 12px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__field {
    @media #{$breakpoint-xl-up} {
      margin-bottom: 40px;
    }
  }

  &__preview {
    margin-top: 15px;

    .info-text {
      margin-top: 16px;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 32px;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 40px 0 80px;
  }
}
