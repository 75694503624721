@import '../../../public/styles/variables';

.form-field {
  margin-bottom: 15px;

  &--error {
    .form-field__label {
      color: $red;
    }
  }

  &__label {
    display: inline-block;
    width: 100%;
    margin-bottom: 8px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    span {
      color: $gray;
      font-size: 13px;
    }

    @media #{$breakpoint-sm-down} {
      margin-bottom: 3px;
      line-height: 16px;
    }
  }

  &__help,
  &__error  {
    margin-top: 2px;
    line-height: 24px;

    @media #{$breakpoint-sm-down} {
      margin-top: 3px;
      font-size: 14px;
      line-height: 14px;
    }
  }

  &__help {
    color: $dark-gray;
    font-size: 16px;
    text-align: left;
  }

  @media #{$breakpoint-md-up} {
    margin-bottom: 25px;
  }
}
