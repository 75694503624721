@import '../../../public/styles/variables';

.title {
  color: $dark;
  font-weight: normal;

  &--bold {
    font-weight: bold;
  }

  &--center {
    text-align: center;
  }

  &--xl {
    font-size: 40px;
    line-height: 50px;

    @media #{$breakpoint-lg-up} {
      font-size: 56px;
      line-height: 59px;
    }
  }

  &--lg {
    font-size: 35px;
    line-height: 45px;

    @media #{$breakpoint-lg-up} {
      font-size: 52px;
      line-height: 55px;
    }
  }

  &--md {
    font-size: 32px;
    line-height: 40px;

    @media #{$breakpoint-lg-up} {
      font-size: 40px;
      line-height: 50px;
    }
  }

  &--sm {
    font-size: 22px;
    line-height: 27px;

    @media #{$breakpoint-lg-up} {
      font-size: 32px;
      line-height: 40px;
    }
  }

  &--xs {
    font-size: 18px;
    line-height: 24px;

    @media #{$breakpoint-lg-up} {
      font-size: 22px;
      line-height: 27px;
    }
  }
}
