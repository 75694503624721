@import '../../../public/styles/variables';

.sign-in {
  .auth__title {
    margin-bottom: 20px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }
  }

  .auth__media {
    margin-bottom: 20px;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 70px;
    }
  }

  .auth__img {
    margin: 0 auto;
  }
}
