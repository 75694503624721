@import '../../../../public/styles/variables';
@import '../../../../public/styles/utils/placeholder.scss';

.field-input {
  position: relative;

  &__field {
    @include placeholder {
      color: $dark-gray;
    }

    width: 100%;
    height: 55px;
    padding: 10px 15px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    font-family: $main-font;
    outline: none;
    border-radius: 8px;
    border: 2px solid $gray;

    &:focus {
      border-color: $yellow;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px $white !important;
      -webkit-text-fill-color: $dark !important;
      color: $dark !important;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  &__eye {
    display: block;
    color: $dark;
    font-size: 24px;
  }
}
