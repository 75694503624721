@import '../../../public/styles/variables';
@import '../../../public/styles/icons';

.action-icon {
  position: relative;
  display: block;
  padding: 0;
  color: $dark;
  font-size: 24px;
  line-height: 0.5;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;

  &:disabled {
    cursor: initial;
  }

  &--favorite {
    &:before {
      @extend .icon;
      @extend .icon-heart-blob;
      color: $pink;
    }
  }

  &--comment {
    &:before {
      @extend .icon;
      @extend .icon-comment-blob;
      color: $light-blue;
    }
  }

  &--like {
    &:before {
      @extend .icon;
      @extend .icon-like-blob;
      color: $yellow;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: inherit;
    transform: scale(0);
    transition: transform ease .3s;
  }

  &--active {
    &:before {
      transform: scale(1);
    }
  }

  &:hover {
    color: $dark-blue;
  }

  &__icon {
    position: relative;
    display: block;
  }
}
