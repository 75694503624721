@import '../../public/styles/variables';

@mixin slick-arrow-base {
  &:before {
    @media #{$breakpoint-lg-up} {
      font-size: 30px !important;
    }

    @media #{$breakpoint-xl-max-up} {
      font-size: 50px !important;
    }
  }
}

@mixin slick-arrow-prev {
  left: -30px !important;

  @media #{$breakpoint-lg-up} {
    left: -45px !important;
  }

  @media #{$breakpoint-xl-max-up} {
    left: -65px !important;
  }
}

@mixin slick-arrow-next {
  right: -30px !important;

  @media #{$breakpoint-lg-up} {
    right: -45px !important;
  }

  @media #{$breakpoint-xl-max-up} {
    right: -65px !important;
  }
}

.condition {
  padding-top: 25px;

  &__header {
    position: relative;
    margin-bottom: 30px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 60px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 100px;
    }
  }

  &__tabs {
    @media #{$breakpoint-sm-down} {
      position: sticky;
      top: 60px; // Header height
      background-color: $white;
      z-index: 5;

      .anchors__list {
        flex-wrap: nowrap;
        overflow: auto;
      }

      .anchors__item {
        white-space: nowrap;
        margin-top: 12px;
      }
    }
  }

  &__title {
    margin-bottom: 12px;

    @media #{$breakpoint-sm-down} {
      font-weight: normal !important;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 20px;
    }
  }

  &__subtext {
    margin-bottom: 24px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 60px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 100px;
    }
  }

  &__search {
    max-width: 655px;
  }

  &__subheader {
    margin-bottom: 10px;

    @media #{$breakpoint-sm-down} {
      .condition__link {
        display: none;
      }
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 24px;
    }
  }

  &__media {
    display: none;

    @media #{$breakpoint-lg-up} {
      position: absolute;
      left: 70%;
      right: 0;
      bottom: 0;
      display: block;
      transform: translateY(50%);
    }

    @media #{$breakpoint-xl-up} {
      left: auto;
      max-width: 440px;
      width: 100%;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    padding: 10px 0 20px;

    @media #{$breakpoint-lg-up} {
      padding: 30px 0 80px;
    }
  }

  &__list {
    padding: 20px 0;

    @media #{$breakpoint-md-down} {
      padding-top: 60px;
      margin-top: -40px;
    }

    @media #{$breakpoint-lg-up} {
      padding: 40px 0;
    }
  }

  &__text {
    margin-top: 8px;
  }

  &__btn {
    margin-top: 12px;

    @media #{$breakpoint-sm-down} {
      width: 100%;
      text-align: center;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 24px;
    }
  }

  &__item {
    @media #{$breakpoint-md-down} {
      margin-bottom: 12px;
    }
  }

  &__link {
    flex: none;
  }

  &__subfooter {
    display: none;

    @media #{$breakpoint-sm-down} {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }

  &__slider {
    .slick-list {
      display: flex;
      margin: 0 -5px;

      @media #{$breakpoint-xl-up} {
        margin: 0 -15px;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-slide {
      padding: 0 5px;

      & > div {
        height: 100%;
      }

      @media #{$breakpoint-xl-up} {
        padding: 0 15px;
      }
    }

    .slick-arrow {
      @include slick-arrow-base;
    }

    .slick-prev {
      @include slick-arrow-prev;
    }

    .slick-next {
      @include slick-arrow-next;
    }

    @media #{$breakpoint-sm-down} {
      margin-top: 30px;
    }

    @media #{$breakpoint-sm-up} {
      padding: 0 30px;
    }

    @media #{$breakpoint-lg-up} {
      padding: 0 45px;
    }

    @media #{$breakpoint-xl-max-up} {
      padding: 0;
    }
  }

  @media #{$breakpoint-md-up} {
    padding-top: 60px;
  }

  @media #{$breakpoint-lg-up} {
    padding-top: 100px;
  }
}

.curerate-choice {
  &__subheader {
    margin-bottom: 36px;
  }

  &__slider {
    padding: 0;
    margin: 0 -5px !important;

    .slick-track {
      margin: 0;
    }

    .slick-arrow {
      @include slick-arrow-base;
    }

    .slick-prev {
      @include slick-arrow-prev;
    }

    .slick-next {
      @include slick-arrow-next;
    }

    @media #{$breakpoint-sm-up} {
      margin: 0 -$gutter !important;
    }
  }

  &__item {
    padding: 0 5px !important;

    @media #{$breakpoint-sm-up} {
      padding: 0 $gutter !important;
    }
  }
}
