.review-details {
  &__header {
    margin-bottom: 30px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 60px;
    }
  }

  &__title {
    margin-bottom: 5px;

    @media #{$breakpoint-sm-down} {
      font-size: 26px;
      line-height: 30px;
    }
  }

  &__body {
    margin-bottom: 30px;
  }

  .review-details__link {
    @media #{$breakpoint-md-up} {
      position: relative;
      font-size: 22px;
      line-height: 27px;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .review-details__card {
    padding: 0;

    .review-card__comments {
      display: none;
    }
  }
}
