.img-lightbox {
  &__modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 60px;
    box-sizing: border-box;
    background-color: $dark;

    &-content {
      width: 90%;
      height: 100%;
      max-height: 100%;
      padding: 0;
      margin: auto;
      text-align: center;
    }

    @media #{$breakpoint-md-up} {
      padding-left: 90px;
      padding-right: 90px;
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
      user-select: none;
    }
  }

  &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    color: $white;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.2;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @media #{$breakpoint-md-up} {
      font-size: 40px;
    }
  }

  &__prev,
  &__next {
    position: absolute;
    display: flex;
    align-items: center;
    height: 80%;
    color: $white;
    font-size: 30px;
    line-height: 60px;
    font-weight: bold;
    opacity: 0.2;
    padding: 0 15px;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 1;
    }

    @media #{$breakpoint-md-up} {
      font-size: 60px;
    }
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }
}
