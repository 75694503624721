@import '../../../public/styles/variables';

.link {
  color: $dark;
  font-size: 16px;
  line-height: 24px;
  transition: ease 0.2s;
  font-family: $main-font;


  &:hover {
    text-decoration: underline;
  }

  &--btn {
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  &--bold {
    font-weight: bold;
  }

  &--icon {
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;

      .link__text {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    margin-top: -4px;
    font-size: 22px;
    text-decoration: none;
  }


}
