@import '../../../public/styles/variables';

.combination-details {
  &__title {
    margin-bottom: 20px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }
  }

  &__intro {
    @media #{$breakpoint-sm-up} {
      display: flex;
      justify-content: space-between;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 24px;
    }
  }

  &__avatar {
    flex: none;
    margin-right: 15px;

    @media #{$breakpoint-md-up} {
      margin-right: 30px;
    }
  }

  &__name {
    margin-bottom: 4px;
  }

  &__body {
    margin-bottom: 25px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }

    @media #{$breakpoint-xl-up} {
      margin-bottom: 80px;
    }
  }

  &__text {
    margin-bottom: 25px;
    color: $dark;
    font-size: 14px;
    line-height: 21px;

    @media #{$breakpoint-md-up} {
      margin-bottom: 40px;
    }
  }

  &__chips {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__actions {
    flex: none;
    margin-bottom: 16px;

    @media #{$breakpoint-sm-up} {
      margin-bottom: 0;
    }
  }

  &__action {
    display: flex;
    align-items: center;

    @media #{$breakpoint-sm-up} {
      flex-direction: column;
    }
  }

  &__count {
    color: $dark;
    font-size: 13px;
    line-height: 19px;
  }

  &__controls {
    margin: 24px 0;
    padding-right: 8px;

    .link:hover {
      text-decoration: none;
    }

    &-edit {
      color: $dark-gray;
    }

    &-delete {
      display: block;
      margin: 16px 0 0;
      color: $red;

      @media #{$breakpoint-md-up} {
        margin-left: auto;
      }
    }

    @media #{$breakpoint-md-up} {
      text-align: right;
    }
  }

  .combination-details__like {
    font-size: 35px;

    @media #{$breakpoint-lg-up} {
      font-size: 55px;
    }
  }
}
