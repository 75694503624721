@import "public/styles/variables";
@import '../../../../public/styles/utils/placeholder.scss';

.field-textarea {
  &__field {
    @include placeholder {
      color: $dark-gray;
    }

    width: 100%;
    padding: 15px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
    font-family: $main-font;
    resize: none;
    outline: none;
    border-radius: 8px;
    border: 2px solid $gray;

    &:focus {
      border-color: $yellow;
    }
  }
}
