@import '../../../../public/styles/variables';

.helpful {

  &__title {
    margin-bottom: 16px;

    @media #{$breakpoint-sm-up} {
      margin-bottom: 25px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 45px;
    }
  }


  &__item {
    position: relative;
    display: block;
    min-height: 260px;
    padding: 24px;
    border: 1px solid $gray;
    transition: 0.3s ease;

    &:hover {
      border-color: transparent;
      background-color: $yellow;

      .helpful__icon {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
    }
  }

  &__subtitle {
    margin-bottom: 12px;
  }

  &__icon {
    margin-left: 10px;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    transform: translateX(-10px);

    @media #{$breakpoint-lg-up} {
      margin-left: 20px;
      font-size: 22px;
    }
  }

  &__count {
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: $gray;

    span {
      color: $dark;
      font-size: 22px;
      line-height: 27px;
    }
  }

  &__media {
    position: absolute;
    right: 20px;
    bottom: 20px;
    max-width: 160px;

    @media #{$breakpoint-sm-up} {
      right: 10px;
      bottom: 20px;
    }

    @media #{$breakpoint-lg-up} {
      max-width: 200px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }
}
