@import '../../../public/styles/variables';

.profile-page {
  padding: 48px 0;

  &__header {
    margin-bottom: 38px;
  }

  &__user {
    display: flex;
    flex-wrap: wrap;
  }

  &__avatar {
    margin-right: 24px;
    flex-shrink: 0;

    @media #{$breakpoint-md-up} {
      width: 185px;
      height: 185px;
      padding: 20px;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 36px;

    &::after {
      content: '';
      display: block;
      width: 80%;
      height: 4px;
      background-image: url('/images/lines/line-gray-1.svg');
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      bottom: -18px;
      left: 0;
    }
  }

  &__favorites-btn {
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;

    .link__text {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    .action-icon {
      margin-right: 8px;
    }
  }

  &__favorites-caption {
    height: 14px;
    line-height: 1rem;
    text-decoration: underline;
  }

  &__controls {
    margin-top: 38px;
    min-width: 100%;

    .link:nth-child(2) {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      color: $red;
      margin-left: 40px;

      &:focus {
        outline: none;
        color: darken($red, 10%);
      }
    }

    @media #{$breakpoint-sm-up} {
      margin-top: 0;
      min-width: unset;
    }
  }

  &__reviews {
    &-title {
      margin-top: 40px;

      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }
    }

    .review-card {
      border-bottom: 1px solid $gray;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__tabs {
    margin-top: 48px;

    &-counter {
      font-weight: normal;
    }

    @media #{$breakpoint-md-up} {
      margin-top: 0;
    }
  }

  &__story {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__recipe {
    min-width: 100%;
    flex-shrink: 0;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__data {
    .success-story {
      height: unset;
    }
  }

  &__pagination {
    margin-top: 48px;
  }

  @media #{$breakpoint-md-up} {
    padding: 84px 0;
  }
}
