@import '../../public/styles/variables';

.cure {
  position: relative;
  border-radius: 4px;
  border: 1px solid $gray;

  &--vertical {
    .cure__favorite {
      top: 14px;
      right: 14px;
    }

    .cure__media {
      padding: 30px 30px 0;
    }

    .cure__img {
      background-size: contain !important;
    }
  }

  &--horizontal {
    display: flex;
    height: 210px;

    .cure__favorite {
      top: 8px;
      right: 8px;
      z-index: 1;

      @media #{$breakpoint-lg-up} {
        top: 14px;
        right: 14px;
      }
    }

    .cure__header {
      height: auto;
      max-height: 48px;
      padding-right: 30px;
    }

    .cure__body {
      margin: auto 0;
    }

    .cure__title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .cure__media {
      flex: none;
      width: 100px;

      @media #{$breakpoint-sm-up} {
        width: 200px;
      }

      @media #{$breakpoint-md-up} {
        width: 140px;
      }

      @media #{$breakpoint-lg-up} {
        width: 40%;
      }

      @media #{$breakpoint-xl-up} {
        width: 50%;
      }
    }

    .cure__img,
    .cure__media {
      height: 100%;
    }

    @media #{$breakpoint-lg-up} {
      height: 250px;
    }
  }

  &__header-hr {
    border-color: $gray;
    border-width: 0 0 1px 0;
    border-style: solid;
    margin: auto 0 25px;
    width: 100%;
  }

  &__favorite {
    position: absolute !important;
  }

  &__media {
    display: block;
    height: 205px;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__content {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media #{$breakpoint-lg-up} {
      padding: 16px 12px;
    }
  }

  &__header {
    height: 48px;
    overflow: hidden;

    @media #{$breakpoint-lg-up} {
      margin-bottom: 5px;
    }
  }

  &__title {
    display: -webkit-box;
    margin-bottom: 2px;
    font-weight: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: color ease 0.3s;
    overflow: hidden;
    word-break: break-all;

    &:hover {
      color: $dark-blue;
      text-decoration: none;
    }

    @supports (word-break: break-word) {
      word-break: break-word !important;
    }
  }

  &__rating {
    text-align: center;

    &-link:hover {
      text-decoration: none;
    }

    &--score {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;
      width: fit-content;
      margin: 0 auto 8px;
    }

    .rating {
      height: 19px;
    }
  }

  &__count {
    margin-left: 8px;
    font-size: 12px;
    line-height: 13px;
    color: $gray;

    > span {
      color: #7f7f7f;
    }

    @media #{$breakpoint-sm-up} {
      font-size: 14px;
      line-height: 15px;
    }
  }

  &__reviews-count {
    font-weight: bold;
    margin: 0 0 4px;
    color: $dark-blue;
    font-size: 14px;
    line-height: 18px;

    > .large {
      font-size: 18px;
    }
  }

  &__reviews-condition {
    font-size: 14px;
    color: #7f7f7f;
  }

  &__author {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 13px;

    .link {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &__unlike {
    &-subtitle {
      margin-bottom: 40px;
    }

    .cure__img {
      width: 170px;
      height: 170px;
      margin: 0 auto 25px;
    }
  }
}
