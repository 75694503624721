.cta {
  padding: 16px 24px;
  margin-bottom: 40px;
  border: 1px solid $gray;

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 24px;
    color: $dark;
    font-size: 16px;
    line-height: 24px;
  }

  &__btn {
    position: relative;

    &:before {
      @extend .icon;
      @extend .icon-spin-arrow-left;
      position: absolute;
      right: -100px;
      bottom: -10px;
      color: $dark;
      font-size: 70px;

      @media #{$breakpoint-md-up} {
        right: -125px;
        font-size: 85px;
      }
    }
  }

  &__media {
    flex: none;
    max-width: 190px;
    margin-left: 10px;

    @media #{$breakpoint-sm-down} {
      display: none;
    }

    @media #{$breakpoint-md-up} {
      max-width: 220px;
    }

    @media #{$breakpoint-xl-up} {
      max-width: 245px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  @media #{$breakpoint-sm-up} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media #{$breakpoint-lg-up} {
    padding: 25px 30px;
    margin-bottom: 80px;
  }

  @media #{$breakpoint-xl-up} {
    align-items: flex-start;
    margin-bottom: 100px;
  }
}
