@import '../../../public/styles/variables';

.banner {
  padding: 20px 0 35px;

  &__content {
    max-width: 625px;
    width: 100%;
    margin: 0 auto;

    @media #{$breakpoint-md-up} {
      max-width: 100%;
    }
  }

  &__title {
    margin-bottom: 25px;

    &-subtitle {
      margin-top: 24px;
      font-size: 24px !important;
      line-height: 30px !important;
      font-weight: 400;

      @media #{$breakpoint-sm-up} {
        font-size: 29px !important;
        line-height: 40px !important;
      }
    }

    @media #{$breakpoint-sm-up} {
      margin-bottom: 35px;
    }

    @media #{$breakpoint-lg-up} {
      margin-bottom: 45px;
    }
  }

  &__text {
    margin-bottom: 15px;
    text-align: center;

    @media #{$breakpoint-sm-up} {
      text-align: left;
    }

    @media #{$breakpoint-md-up} {
      margin-bottom: 35px;
    }

    @media #{$breakpoint-xl-up} {
      display: flex;
      align-items: center;
    }
  }

  &__choose {
    @media #{$breakpoint-sm-up} {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    @media #{$breakpoint-xl-up} {
      margin-top: 0;
    }
  }

  &__select {
    margin: 10px 0;

    @media #{$breakpoint-sm-up} {
      margin: 0 10px;
    }

    @media #{$breakpoint-xl-down} {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__btn {
    @media #{$breakpoint-xs-only} {
      width: 100%;
    }
  }

  &__media {
    @media #{$breakpoint-md-down} {
      display: none;
    }

    @media #{$breakpoint-xl-down} {
      padding-right: 60px;
    }

    @media #{$breakpoint-xl-up} {
      max-width: 385px;
      width: 100%;
      margin-left: auto;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  .condition--contact-us {
    display: flex;
    align-items: center;
    justify-content: center;

    > .icon {
      margin-right: 6px;
      margin-top: -2px;
    }
  }

  @media #{$breakpoint-md-up} {
    padding: 40px 0 60px;
  }

  @media #{$breakpoint-lg-up} {
    padding-bottom: 70px;
  }
}
