@import '../../../public/styles/variables';

.social-auth {
  display: flex;

  .social-auth__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 255px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: normal;

    @media #{$breakpoint-xs-only} {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media #{$breakpoint-sm-up} {
      margin: 0;
    }
  }

  &__logo {
    margin-right: 10px;
  }

  &__terms {
    color: $dark;
    font-size: 13px;
    line-height: 19px;
    opacity: 0.5;
  }

  @media #{$breakpoint-xs-only} {
    align-items: center;
    flex-direction: column;
  }

  @media #{$breakpoint-sm-up} {
    justify-content: space-between;
  }
}
